import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardRow,
  Checkbox,
  EmptyState,
  IconButton,
  IconDeleteBin,
  IconFilter,
  IconUserReceived2,
  InlineNotification,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableRowHead,
  Text,
  TextShortener,
  ToolTip,
} from '@userclouds/ui-component-lib';

import { makeCleanPageLink } from '../AppNavigation';
import { RootState, AppDispatch } from '../store';
import { Column } from '../models/TenantUserStoreConfig';
import Mutator, { MUTATOR_COLUMNS, MUTATOR_PREFIX } from '../models/Mutator';
import { SelectedTenant } from '../models/Tenant';
import PaginatedResult from '../models/PaginatedResult';
import {
  bulkDeleteMutatorsOrAccessors,
  deleteSingleMutator,
  fetchMutators,
} from '../thunks/userstore';

import {
  changeMutatorSearchFilter,
  toggleMutatorForDelete,
} from '../actions/mutators';
import Link from '../controls/Link';
import Pagination from '../controls/Pagination';
import PageCommon from './PageCommon.module.css';
import Search from '../controls/Search';
import { Filter } from '../models/authz/SearchFilters';
import Styles from './MutatorsPage.module.css';

const MutatorList = ({
  selectedCompanyID,
  selectedTenant,
  mutators,
  userStoreColumns,
  isFetching,
  fetchError,
  deleteQueue,
  editMode,
  isSaving,
  saveSuccess,
  saveErrors,
  query,
  mutatorSearchFilter,
  dispatch,
}: {
  selectedCompanyID: string | undefined;
  selectedTenant: SelectedTenant | undefined;
  mutators: PaginatedResult<Mutator> | undefined;
  userStoreColumns: Column[] | undefined;
  isFetching: boolean;
  fetchError: string;
  deleteQueue: Record<string, Mutator>;
  editMode: boolean;
  isSaving: boolean;
  saveSuccess: string;
  saveErrors: string[];
  query: URLSearchParams;
  mutatorSearchFilter: Filter;
  dispatch: AppDispatch;
}) => {
  const cleanQuery = makeCleanPageLink(query);
  const isDirty = Object.keys(deleteQueue).length;

  return (
    <>
      <div className={PageCommon.listviewtablecontrols}>
        <div>
          <IconFilter />
        </div>
        <Search
          columns={MUTATOR_COLUMNS}
          changeSearchFilter={(filter: Filter) => {
            dispatch(changeMutatorSearchFilter(filter));
          }}
          prefix={MUTATOR_PREFIX}
          searchFilter={mutatorSearchFilter}
        />
        <div className={PageCommon.listviewtablecontrolsToolTip}>
          <ToolTip>
            <>
              {
                'Mutators are configurable APIs that allow a client to write data to the User Store. '
              }
              <a
                href="https://docs.userclouds.com/docs/mutators-write-apis"
                title="UserClouds documentation for mutators (write APIs)"
                target="new"
                className={PageCommon.link}
              >
                Learn more here.
              </a>
            </>
          </ToolTip>
        </div>

        {selectedTenant?.is_admin && (
          <Button
            theme="primary"
            size="small"
            className={PageCommon.listviewtablecontrolsButton}
          >
            <Link href={`/mutators/create${cleanQuery}`} applyStyles={false}>
              Create Mutator
            </Link>
          </Button>
        )}
      </div>

      <Card
        id="userstoreMutators"
        lockedMessage={
          !selectedTenant?.is_admin ? 'You do not have edit access' : ''
        }
        listview
      >
        {mutators ? (
          mutators.data && mutators.data.length ? (
            <>
              {saveSuccess && (
                <InlineNotification theme="success">
                  {saveSuccess}
                </InlineNotification>
              )}
              {!!saveErrors.length && (
                <InlineNotification theme="alert">
                  {saveErrors.length > 1
                    ? `Error deleting ${saveErrors.length} mutators`
                    : saveErrors[0]}
                </InlineNotification>
              )}
              <div className={PageCommon.listviewpaginationcontrols}>
                <IconButton
                  icon={<IconDeleteBin />}
                  onClick={() => {
                    if (
                      window.confirm(
                        `Are you sure you want to delete ${isDirty} mutator${
                          isDirty > 1 ? 's' : ''
                        }?`
                      )
                    ) {
                      dispatch(
                        bulkDeleteMutatorsOrAccessors(
                          selectedTenant?.id || '',
                          deleteQueue,
                          'mutator'
                        )
                      );
                    }
                  }}
                  size="small"
                  disabled={isDirty < 1}
                  className={PageCommon.listviewpaginationcontrolsdelete}
                  id="delete"
                />
                <Pagination
                  prev={mutators?.prev}
                  next={mutators?.next}
                  isLoading={isFetching}
                  prefix={MUTATOR_PREFIX}
                />
              </div>
              <Table
                spacing="nowrap"
                id="mutators"
                className={Styles.mutatorListTable}
              >
                <TableHead floating>
                  <TableRow>
                    <TableRowHead className={Styles.checkboxCol}>
                      <Checkbox
                        checked={
                          Object.keys(deleteQueue).length ===
                          mutators.data.length
                        }
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const shouldMarkForDelete =
                            !deleteQueue[mutators.data[0].id];
                          mutators.data.forEach((o) => {
                            if (shouldMarkForDelete && !deleteQueue[o.id]) {
                              dispatch(toggleMutatorForDelete(o));
                            } else if (
                              !shouldMarkForDelete &&
                              deleteQueue[o.id]
                            ) {
                              dispatch(toggleMutatorForDelete(o));
                            }
                          });
                        }}
                      />
                    </TableRowHead>
                    <TableRowHead key="mutator_name" className={Styles.nameCol}>
                      Name
                    </TableRowHead>
                    <TableRowHead
                      key="mutator_table"
                      className={Styles.tablesCol}
                    >
                      Table
                    </TableRowHead>
                    <TableRowHead
                      key="mutator_columns"
                      className={Styles.columnsCol}
                    >
                      Columns
                    </TableRowHead>
                    <TableRowHead
                      key="mutator_where_clause"
                      className={Styles.whereClauseCol}
                    >
                      Where Clause
                    </TableRowHead>
                    <TableRowHead
                      key="mutator_version"
                      className={Styles.versionCol}
                    >
                      Version
                    </TableRowHead>
                    <TableRowHead key="mutator_id" className={Styles.idCol}>
                      ID
                    </TableRowHead>
                    <TableRowHead
                      key="delete_mutator"
                      className={Styles.deleteCol}
                    />
                    <TableRowHead className={Styles.chevronCol} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mutators.data.map((mutator) => (
                    <TableRow
                      key={mutator.id}
                      isExtensible
                      className={
                        (deleteQueue[mutator.id]
                          ? PageCommon.queuedfordelete
                          : '') +
                        ' ' +
                        PageCommon.listviewtablerow
                      }
                    >
                      <TableCell>
                        <Checkbox
                          id={'delete' + mutator.id}
                          name="delete mutator"
                          checked={deleteQueue[mutator.id]}
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            dispatch(toggleMutatorForDelete(mutator));
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <Link
                          href={`/mutators/${mutator.id}/${mutator.version}${cleanQuery}`}
                          title="View details for this mutator"
                        >
                          {mutator.name}
                        </Link>
                      </TableCell>
                      <TableCell>
                        {mutator.columns
                          .map((col) => col.table)
                          .filter((value, index, self) => {
                            return self.indexOf(value) === index;
                          })
                          .join(', ')}
                      </TableCell>
                      <TableCell>
                        {mutator.columns.map((col) => col.name).join(', ')}
                      </TableCell>
                      <TableCell>
                        {mutator.selector_config.where_clause}
                      </TableCell>
                      <TableCell>{mutator.version}</TableCell>
                      <TableCell>
                        <TextShortener text={mutator.id} length={8} />
                      </TableCell>
                      <TableCell className={PageCommon.listviewtabledeletecell}>
                        <IconButton
                          icon={<IconDeleteBin />}
                          onClick={() => {
                            if (selectedTenant) {
                              dispatch(
                                deleteSingleMutator(
                                  selectedTenant.id,
                                  mutator.id
                                )
                              );
                            }
                          }}
                          title="Delete mutator"
                          size="tiny"
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </>
          ) : (
            <CardRow>
              <EmptyState
                title="No mutators"
                image={<IconUserReceived2 size="large" />}
              >
                {selectedTenant?.is_admin && (
                  <Button theme="secondary">
                    <Link
                      href={`/mutators/create${cleanQuery}`}
                      applyStyles={false}
                    >
                      + Add mutator
                    </Link>
                  </Button>
                )}
              </EmptyState>
            </CardRow>
          )
        ) : isFetching ? (
          <Text>Fetching tenant mutators...</Text>
        ) : (
          <InlineNotification theme="alert">
            {fetchError || 'Something went wrong'}
          </InlineNotification>
        )}
      </Card>
    </>
  );
};
const ConnectedMutatorList = connect((state: RootState) => ({
  selectedCompanyID: state.selectedCompanyID,
  selectedTenant: state.selectedTenant,
  mutators: state.mutators,
  userStoreColumns: state.userStoreColumns,
  isFetching: state.fetchingMutators,
  fetchError: state.fetchMutatorsError,
  deleteQueue: state.mutatorsToDelete,
  editMode: state.mutatorListEditMode,
  isSaving: state.updatingMutators,
  saveSuccess: state.bulkUpdateMutatorsSuccess,
  saveErrors: state.bulkUpdateMutatorsErrors,
  query: state.query,
  mutatorSearchFilter: state.mutatorSearchFilter,
}))(MutatorList);

const MutatorsPage = ({
  selectedTenantID,

  query,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchMutators(selectedTenantID, query));
    }
  }, [selectedTenantID, query, dispatch]);

  return <ConnectedMutatorList />;
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  query: state.query,
}))(MutatorsPage);
