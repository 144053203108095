import React from 'react';
import { IconTypes, getSize } from '../iconHelpers';

function IconInformationTip({
  className,
  size = 'medium',
}: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      className={className}
      fill="currentColor"
    >
      <title>Information Tip</title>
      <path
        d="M10.0001 17.3333C5.39758 17.3333 1.66675 13.6025 1.66675 8.99996C1.66675 4.39746 5.39758 0.666626 10.0001 0.666626C14.6026 0.666626 18.3334 4.39746 18.3334 8.99996C18.3334 13.6025 14.6026 17.3333 10.0001 17.3333ZM9.16675 8.16663V13.1666H10.8334V8.16663H9.16675ZM9.16675 4.83329V6.49996H10.8334V4.83329H9.16675Z"
        fill="#283354"
      />{' '}
    </svg>
  );
}

export default IconInformationTip;
