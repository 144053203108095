import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';

import {
  Button,
  Card,
  GlobalStyles,
  Heading,
  IconButton,
  IconDeleteBin,
  InputReadOnly,
  Label,
  Select,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowHead,
  Text,
  TextInput,
  ButtonGroup,
} from '@userclouds/ui-component-lib';

import { AppDispatch, RootState } from '../store';

import { SelectedTenant } from '../models/Tenant';
import { ObjectType } from '../models/authz/ObjectType';
import EdgeType, {
  Attribute,
  AttributeFlavors,
} from '../models/authz/EdgeType';
import {
  fetchAuthZObjectTypes,
  fetchAuthZEdgeTypes,
  fetchAuthZEdgeType,
  createEdgeType,
  updateEdgeType,
} from '../thunks/authz';
import PageCommon from './PageCommon.module.css';
import {
  changeAttributeFlavorForEdgeType,
  addRowToEdgeType,
  deleteAttributeForEdgeType,
  changeEdgeTypeAttribute,
  changeEdgeType,
  retrieveBlankEdgeType,
  toggleEdgeTypeEditMode,
} from '../actions/authz';
import { FeatureFlags } from '../models/FeatureFlag';
import PaginatedResult from '../models/PaginatedResult';

const AttributeRow = ({
  attribute,
  edgeType,
  readOnly,
  index,
}: {
  attribute: Attribute;
  edgeType: EdgeType;
  readOnly: boolean;
  index: number;
}) => {
  let flavor = '';
  if (attribute.direct) {
    flavor = AttributeFlavors.direct;
  } else if (attribute.propagate) {
    flavor = AttributeFlavors.propagate;
  } else {
    flavor = AttributeFlavors.inherit;
  }
  const dispatch: AppDispatch = useDispatch();
  return (
    <TableRow>
      <TableCell>
        {readOnly ? (
          <InputReadOnly>{attribute.name}</InputReadOnly>
        ) : (
          <TextInput
            id={`attributeName` + index}
            value={attribute.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              attribute.name = e.target.value;
              dispatch(changeEdgeTypeAttribute(attribute));
            }}
          />
        )}
      </TableCell>
      <TableCell>
        {readOnly ? (
          <InputReadOnly>{flavor}</InputReadOnly>
        ) : (
          <Select
            id={`attributeFlavor` + index}
            name={'attribute_flavor_' + index}
            items={[
              AttributeFlavors.direct,
              AttributeFlavors.inherit,
              AttributeFlavors.propagate,
            ]}
            value={flavor}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              dispatch(
                changeAttributeFlavorForEdgeType(attribute, e.target.value)
              );
            }}
          >
            {[
              AttributeFlavors.direct,
              AttributeFlavors.inherit,
              AttributeFlavors.propagate,
            ].map((t) => (
              <option key={t + index} value={t}>
                {t}
              </option>
            ))}
          </Select>
        )}
      </TableCell>
      {!readOnly && (
        <TableCell>
          <IconButton
            icon={<IconDeleteBin />}
            onClick={() => {
              dispatch(deleteAttributeForEdgeType(attribute, edgeType));
            }}
            title="Delete Field"
          />
        </TableCell>
      )}
    </TableRow>
  );
};

const SchemaTable = ({
  edgeType,
  readOnly,
}: {
  edgeType: EdgeType;
  readOnly: boolean;
}) => {
  return (
    <Table id="attributes">
      <TableHead>
        <TableRow>
          <TableRowHead key="attribute_name_header">
            Attribute Name
          </TableRowHead>
          <TableRowHead key="attribute_flavor_header">Flavor</TableRowHead>
          {!readOnly && <TableRowHead key="attribute_delete_header" />}
        </TableRow>
      </TableHead>
      <TableBody>
        {edgeType.attributes &&
          edgeType.attributes.map((attribute, i) => (
            <AttributeRow
              attribute={attribute}
              edgeType={edgeType}
              readOnly={readOnly}
              key={attribute.id}
              index={i}
            />
          ))}
      </TableBody>
    </Table>
  );
};

const EdgeTypeEditPage = ({
  objectTypes,
  edgeType,
  edgeTypes,
  selectedTenant,
  saveSuccess,
  saveError,
  editMode,
  isNewPage,
  edgeTypeID,
  dispatch,
}: {
  objectTypes: PaginatedResult<ObjectType> | undefined;
  edgeType: EdgeType | undefined;
  edgeTypes: PaginatedResult<EdgeType> | undefined;
  edgeTypeFetchError: string;
  selectedTenant: SelectedTenant | undefined;
  saveSuccess: string;
  saveError: string;
  editMode: boolean;
  isNewPage: boolean;
  edgeTypeID: string | undefined;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenant) {
      if (!objectTypes) {
        dispatch(
          fetchAuthZObjectTypes(selectedTenant.id, new URLSearchParams())
        );
      }
      if (!edgeTypes) {
        dispatch(fetchAuthZEdgeTypes(selectedTenant.id, new URLSearchParams()));
      }
    }
  }, [dispatch, objectTypes, edgeTypes, selectedTenant]);

  useEffect(() => {
    if (selectedTenant) {
      if (edgeTypeID) {
        dispatch(fetchAuthZEdgeType(selectedTenant.id, edgeTypeID));
      } else if (objectTypes) {
        dispatch(retrieveBlankEdgeType());
      }
    }
  }, [dispatch, selectedTenant, edgeTypeID, objectTypes]);

  const readOnly = !selectedTenant?.is_admin || !editMode;

  return edgeType ? (
    <Card title="Edge Type">
      <Text className={GlobalStyles['max-w-4xl']}>
        Configure the basic details and attributes of this edge type.
      </Text>
      <Heading size="3" headingLevel="3">
        Basic Details
      </Heading>
      <div className={PageCommon.carddetailsrow}>
        <Label htmlFor="name">
          Name
          {readOnly ? (
            <InputReadOnly>{edgeType.type_name}</InputReadOnly>
          ) : (
            <TextInput
              id="name"
              value={edgeType.type_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(changeEdgeType({ type_name: e.target.value }));
              }}
            />
          )}
        </Label>

        <Label>
          ID
          <InputReadOnly>{edgeType.id}</InputReadOnly>
        </Label>
      </div>

      <div className={PageCommon.carddetailsrow}>
        <Label>
          Source Object Type
          {!objectTypes ? (
            <Text element="h4">Loading...</Text>
          ) : (
            <>
              {readOnly || !isNewPage ? (
                <InputReadOnly>
                  {
                    objectTypes?.data?.find(
                      (t) => t.id === edgeType.source_object_type_id
                    )?.type_name
                  }
                </InputReadOnly>
              ) : (
                objectTypes?.data && (
                  <Select
                    id="sourceObjectType"
                    name="source_object_type"
                    items={objectTypes}
                    value={
                      objectTypes?.data?.find(
                        (t) => t.id === edgeType.source_object_type_id
                      )?.type_name
                    }
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      dispatch(
                        changeEdgeType({
                          source_object_type_id: objectTypes.data.find(
                            (t) => t.type_name === e.target.value
                          )?.id!,
                        })
                      );
                    }}
                  >
                    {objectTypes.data.map((t) => (
                      <option key={t.id} value={t.type_name}>
                        {t.type_name}
                      </option>
                    ))}
                  </Select>
                )
              )}
            </>
          )}
        </Label>
        <Label>
          Target Object Type
          {!objectTypes ? (
            <Text element="h4">Loading...</Text>
          ) : (
            <>
              {readOnly || !isNewPage ? (
                <InputReadOnly>
                  {
                    objectTypes.data.find(
                      (t) => t.id === edgeType.target_object_type_id
                    )?.type_name
                  }
                </InputReadOnly>
              ) : (
                objectTypes?.data && (
                  <Select
                    id="targetObjectType"
                    name="target_object_type"
                    items={objectTypes}
                    value={
                      objectTypes?.data?.find(
                        (t) => t.id === edgeType.target_object_type_id
                      )?.type_name
                    }
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      objectTypes?.data &&
                        dispatch(
                          changeEdgeType({
                            target_object_type_id: objectTypes.data.find(
                              (t) => t.type_name === e.target.value
                            )?.id!,
                          })
                        );
                    }}
                  >
                    {objectTypes.data.map((t) => (
                      <option key={t.id} value={t.type_name}>
                        {t.type_name}
                      </option>
                    ))}
                  </Select>
                )
              )}
            </>
          )}
        </Label>
      </div>
      <Heading size="3" headingLevel="3">
        Attributes
      </Heading>

      <SchemaTable edgeType={edgeType} readOnly={readOnly} />

      {selectedTenant?.is_admin && (
        <>
          {editMode && (
            <Button
              theme="secondary"
              onClick={() => {
                dispatch(addRowToEdgeType());
              }}
            >
              Add Attribute
            </Button>
          )}

          {saveSuccess && (
            <InlineNotification theme="success">
              {saveSuccess}
            </InlineNotification>
          )}
          {saveError && (
            <InlineNotification theme="alert">{saveError}</InlineNotification>
          )}

          {editMode ? (
            <ButtonGroup>
              <Button
                theme="primary"
                disabled={edgeType.type_name === ''}
                onClick={() => {
                  isNewPage
                    ? dispatch(
                        createEdgeType(selectedTenant?.id || '', edgeType)
                      )
                    : dispatch(
                        updateEdgeType(selectedTenant?.id || '', edgeType)
                      );
                }}
              >
                {isNewPage ? 'Create edge type' : 'Save changes'}
              </Button>
              {!isNewPage && (
                <Button
                  theme="secondary"
                  onClick={() => {
                    dispatch(toggleEdgeTypeEditMode(false));
                    if (edgeTypeID) {
                      dispatch(
                        fetchAuthZEdgeType(selectedTenant.id, edgeTypeID)
                      );
                    }
                  }}
                >
                  Cancel
                </Button>
              )}
            </ButtonGroup>
          ) : (
            <Button
              theme="secondary"
              onClick={() => {
                dispatch(toggleEdgeTypeEditMode(true));
              }}
            >
              Edit
            </Button>
          )}
        </>
      )}
    </Card>
  ) : (
    <Text>Fetching Edge Type...</Text>
  );
};

const AuthZEdgeTypePage = ({
  objectTypes,
  edgeType,
  edgeTypes,
  edgeTypeFetchError,
  selectedTenant,
  saveSuccess,
  saveError,
  editMode,
  location,
  query,
  routeParams,
  featureFlags,
  dispatch,
}: {
  objectTypes: PaginatedResult<ObjectType> | undefined;
  edgeType: EdgeType | undefined;
  edgeTypes: PaginatedResult<EdgeType> | undefined;
  edgeTypeFetchError: string;
  selectedTenant: SelectedTenant | undefined;
  saveSuccess: string;
  saveError: string;
  editMode: boolean;
  location: URL;
  query: URLSearchParams;
  routeParams: Record<string, string>;
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}) => {
  const { pathname } = location;
  const { edgeTypeID } = routeParams;
  const isNewPage = pathname.indexOf('create') > -1;

  return (
    <EdgeTypeEditPage
      edgeTypeID={edgeTypeID}
      isNewPage={isNewPage}
      objectTypes={objectTypes}
      edgeType={edgeType}
      edgeTypes={edgeTypes}
      edgeTypeFetchError={edgeTypeFetchError}
      selectedTenant={selectedTenant}
      saveSuccess={saveSuccess}
      saveError={saveError}
      editMode={editMode}
      dispatch={dispatch}
    />
  );
};

const ConnectedAuthZEdgeTypePage = connect((state: RootState) => {
  return {
    objectTypes: state.objectTypes,
    objectTypeError: state.fetchObjectTypesError,
    edgeType: state.currentEdgeType,
    edgeTypes: state.edgeTypes,
    edgeTypeFetchError: state.fetchEdgeTypesError,
    selectedTenant: state.selectedTenant,
    saveSuccess: state.saveEdgeTypeSuccess,
    saveError: state.saveEdgeTypeError,
    editMode: state.edgeTypeEditMode,
    location: state.location,
    query: state.query,
    routeParams: state.routeParams,
    featureFlags: state.featureFlags,
  };
})(AuthZEdgeTypePage);

export default ConnectedAuthZEdgeTypePage;
