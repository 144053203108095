import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardRow,
  EmptyState,
  Heading,
  IconButton,
  IconDeleteBin,
  IconLock2,
  InputReadOnly,
  Label,
  InlineNotification,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowHead,
  Text,
  TextInput,
  TextShortener,
} from '@userclouds/ui-component-lib';

import { AppDispatch, RootState } from '../store';
import { makeCleanPageLink } from '../AppNavigation';
import {
  fetchAuthZObjectTypes,
  fetchAuthZObjectType,
  fetchAuthZEdgeTypes,
  createObjectType,
} from '../thunks/authz';
import {
  retrieveBlankObjectType,
  changeObjectType,
  toggleEdgeTypeForDelete,
} from '../actions/authz';
import EdgeType, { getEdgeTypeFilteredByType } from '../models/authz/EdgeType';
import { ObjectType } from '../models/authz/ObjectType';
import { SelectedTenant } from '../models/Tenant';
import Link from '../controls/Link';
import ObjectTable from '../controls/ObjectTable';
import PageCommon from './PageCommon.module.css';
import { FeatureFlags } from '../models/FeatureFlag';
import PaginatedResult from '../models/PaginatedResult';

const CreateObjectTypeEditPage = ({
  objectType,
  selectedTenantID,
  saveSuccess,
  saveError,
  routeParams,
  dispatch,
}: {
  objectType: ObjectType | undefined;
  objectTypeFetchError: string;
  selectedTenantID: string | undefined;
  saveSuccess: string;
  saveError: string;
  routeParams: Record<string, string>;
  dispatch: AppDispatch;
}) => {
  const { objectTypeID } = routeParams;
  const isCreatePage = window.location.pathname.indexOf('create') > -1;
  useEffect(() => {
    if (selectedTenantID) {
      if (objectTypeID) {
        dispatch(fetchAuthZObjectType(selectedTenantID, objectTypeID));
      } else {
        dispatch(retrieveBlankObjectType());
      }
    }
  }, [dispatch, selectedTenantID, objectTypeID]);

  return objectType ? (
    <Card
      title="Basic Details"
      description=" Configure the basic details and attributes of this object type."
    >
      <div className={PageCommon.carddetailsrow}>
        <Label htmlFor="name">
          Name
          {isCreatePage ? (
            <TextInput
              id="name"
              value={objectType.type_name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(changeObjectType({ type_name: e.target.value }));
              }}
            />
          ) : (
            <InputReadOnly>{objectType.type_name}</InputReadOnly>
          )}
        </Label>
        <Label>
          ID
          <InputReadOnly monospace>{objectType.id}</InputReadOnly>
        </Label>
      </div>

      {saveSuccess && (
        <InlineNotification theme="success">{saveSuccess}</InlineNotification>
      )}
      {!saveSuccess && saveError && (
        <InlineNotification theme="alert">{saveError}</InlineNotification>
      )}
      {isCreatePage && (
        <Button
          theme="primary"
          disabled={!(objectType && objectType.type_name)}
          onClick={() => {
            dispatch(createObjectType(selectedTenantID as string, objectType));
          }}
        >
          Create object type
        </Button>
      )}
    </Card>
  ) : (
    <Text>Fetching Object Type...</Text>
  );
};

const ConnectedCreateObjectTypePage = connect((state: RootState) => {
  return {
    objectTypes: state.objectTypes,
    objectTypeError: state.fetchObjectTypesError,
    objectType: state.currentObjectType,
    objectTypeFetchError: state.fetchObjectTypesError,
    selectedTenantID: state.selectedTenantID,
    saveSuccess: state.saveObjectTypeSuccess,
    saveError: state.saveObjectTypeError,
    routeParams: state.routeParams,
  };
})(CreateObjectTypeEditPage);

const EdgeTypesRow = ({
  edgeType,
  objectTypes,
  edgeTypeEditMode,
  edgeTypeDeleteQueue,
  query,
  featureFlags,
  dispatch,
}: {
  edgeType: EdgeType;
  objectTypes: PaginatedResult<ObjectType>;
  edgeTypeEditMode: boolean;
  edgeTypeDeleteQueue: string[];
  query: URLSearchParams;
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}) => {
  if (!objectTypes || !objectTypes.data) {
    return <Heading>Loading...</Heading>;
  }
  const sourceObjectType = objectTypes.data.find(
    (ot) => ot.id === edgeType.source_object_type_id
  );
  const targetObjectType = objectTypes.data.find(
    (ot) => ot.id === edgeType.target_object_type_id
  );

  return (
    <TableRow
      key={edgeType.id}
      title={
        edgeTypeDeleteQueue.includes(edgeType.id) ? 'Queued for delete' : ''
      }
      className={
        edgeTypeDeleteQueue.includes(edgeType.id)
          ? PageCommon.queuedfordelete
          : ''
      }
    >
      <TableCell>
        <Link
          key={edgeType.id}
          href={`/edgetypes/${edgeType.id}` + makeCleanPageLink(query)}
        >
          {edgeType.type_name}
        </Link>
      </TableCell>
      <TableCell>
        <TextShortener text={edgeType.id} length={36} />
      </TableCell>
      <TableCell>
        {sourceObjectType
          ? sourceObjectType.type_name
          : edgeType.source_object_type_id}
      </TableCell>
      <TableCell>
        {targetObjectType
          ? targetObjectType.type_name
          : edgeType.target_object_type_id}
      </TableCell>
      {edgeTypeEditMode && (
        <TableCell>
          <IconButton
            icon={<IconDeleteBin />}
            onClick={() => {
              dispatch(toggleEdgeTypeForDelete(edgeType.id));
            }}
            title="Delete Edge Type"
          />
        </TableCell>
      )}
    </TableRow>
  );
};

const EdgeTypes = ({
  selectedTenant,
  objectTypes,
  objectTypeError,
  edgeTypes,
  etError,
  edgeTypeEditMode,
  edgeTypeDeleteQueue,
  targetObjectTypeID,
  sourceObjectTypeID,
  query,
  featureFlags,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  objectTypes: PaginatedResult<ObjectType> | undefined;
  objectTypeError: string;
  edgeTypes: PaginatedResult<EdgeType> | undefined;
  etError: string;
  edgeTypeEditMode: boolean;
  edgeTypeDeleteQueue: string[];
  targetObjectTypeID: string | undefined;
  sourceObjectTypeID: string | undefined;
  query: URLSearchParams;
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}) => {
  return (
    <Card
      title={
        'Edge Types with this ' + (sourceObjectTypeID ? 'Source' : 'Target')
      }
      description={
        <>
          {'View all edge types with this object type as their ' +
            (sourceObjectTypeID ? 'Source' : 'Target') +
            '. '}
          <a
            href="https://docs.userclouds.com/docs/key-concepts-1#edge-types"
            title="UserClouds documentation for key concepts in authorization"
          >
            Learn more about edge types here.
          </a>
        </>
      }
    >
      {objectTypeError || etError ? (
        <Text element="h4">{objectTypeError || etError}</Text>
      ) : !objectTypes || !edgeTypes ? (
        <Text element="h4">Loading...</Text>
      ) : !edgeTypes ||
        !edgeTypes.data ||
        getEdgeTypeFilteredByType(
          edgeTypes,
          targetObjectTypeID,
          sourceObjectTypeID
        ).length === 0 ? (
        <CardRow>
          <EmptyState title="No Edges" image={<IconLock2 size="large" />}>
            {selectedTenant?.is_admin && (
              <Link
                href={'/edgetypes/create' + makeCleanPageLink(query)}
                applyStyles={false}
              >
                <Button theme="secondary">Create Edge Type</Button>
              </Link>
            )}
          </EmptyState>
        </CardRow>
      ) : (
        <>
          <Table>
            <TableHead>
              <TableRow>
                <TableRowHead>Type Name</TableRowHead>
                <TableRowHead>ID</TableRowHead>
                <TableRowHead>Source Object Type</TableRowHead>
                <TableRowHead>Target Object Type</TableRowHead>
                {edgeTypeEditMode && <TableRowHead key="delete_head" />}
              </TableRow>
            </TableHead>
            <TableBody>
              {edgeTypes?.data &&
                getEdgeTypeFilteredByType(
                  edgeTypes,
                  targetObjectTypeID,
                  sourceObjectTypeID
                ).map((et) => (
                  <EdgeTypesRow
                    edgeType={et}
                    objectTypes={objectTypes}
                    key={et.id}
                    edgeTypeEditMode={edgeTypeEditMode}
                    edgeTypeDeleteQueue={edgeTypeDeleteQueue}
                    query={query}
                    featureFlags={featureFlags}
                    dispatch={dispatch}
                  />
                ))}
            </TableBody>
          </Table>
          {selectedTenant?.is_admin && (
            <Link
              href={'/edgetypes/create' + makeCleanPageLink(query)}
              applyStyles={false}
            >
              <Button theme="secondary">Create Edge Type</Button>
            </Link>
          )}
        </>
      )}
    </Card>
  );
};
const ConnectedEdgeTypes = connect((state: RootState) => {
  return {
    edgeType: state.edgeTypes,
    eError: state.fetchEdgeTypesError,
    edgeTypeEditMode: state.edgeTypeEditMode,
    editSuccess: state.editObjectsSuccess,
    editError: state.editObjectsError,
    edgeTypeDeleteQueue: state.edgeTypeDeleteQueue,
    savingEdgeTypes: state.savingEdgeTypes,
    query: state.query,
    featureFlags: state.featureFlags,
  };
})(EdgeTypes);

const AuthZObjectTypePage = ({
  objectTypes,
  objectTypeError,
  edgeTypes,
  edgeTypeError,
  selectedTenant,
  edgeTypeEditMode,
  edgeTypeDeleteQueue,
  objectTypeEditMode,
  objectTypeDeleteQueue,
  location,
  query,
  routeParams,
  featureFlags,
  dispatch,
}: {
  objectTypes: PaginatedResult<ObjectType> | undefined;
  objectTypeError: string;
  edgeTypes: PaginatedResult<EdgeType> | undefined;
  edgeTypeError: string;
  selectedTenant: SelectedTenant | undefined;
  edgeTypeEditMode: boolean;
  edgeTypeDeleteQueue: string[];
  objectTypeEditMode: boolean;
  objectTypeDeleteQueue: string[];
  location: URL;
  query: URLSearchParams;
  routeParams: Record<string, string>;
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}) => {
  const { pathname } = location;
  const { objectTypeID } = routeParams;
  useEffect(() => {
    if (selectedTenant) {
      dispatch(fetchAuthZObjectTypes(selectedTenant.id, new URLSearchParams()));
      dispatch(fetchAuthZEdgeTypes(selectedTenant.id, new URLSearchParams()));
    }
  }, [dispatch, selectedTenant]);
  const isCreatePage = pathname.indexOf('create') > -1;
  return (
    <>
      <ConnectedCreateObjectTypePage />
      {!isCreatePage && (
        <ConnectedEdgeTypes
          sourceObjectTypeID={objectTypeID}
          targetObjectTypeID={undefined}
          selectedTenant={selectedTenant}
          objectTypes={objectTypes}
          objectTypeError={objectTypeError}
          edgeTypes={edgeTypes}
          etError={edgeTypeError}
        />
      )}
      {!isCreatePage && (
        <ConnectedEdgeTypes
          sourceObjectTypeID={undefined}
          targetObjectTypeID={objectTypeID}
          selectedTenant={selectedTenant}
          objectTypes={objectTypes}
          objectTypeError={objectTypeError}
          edgeTypes={edgeTypes}
          etError={edgeTypeError}
        />
      )}
      {!isCreatePage && (
        <ObjectTable
          title="Objects"
          description="View all objects of this particular object type."
          selectedTenant={selectedTenant}
          objectTypes={objectTypes}
          objectTypeError={objectTypeError}
          objectTypeID={objectTypeID}
          useOldLayout
        />
      )}
    </>
  );
};

const ConnectedAuthZObjectTypePage = connect((state: RootState) => {
  return {
    objectTypes: state.objectTypes,
    objectTypeError: state.fetchObjectTypesError,
    edgeTypes: state.edgeTypes,
    edgeTypeError: state.fetchEdgeTypesError,
    selectedTenant: state.selectedTenant,
    edgeTypeEditMode: state.edgeTypeEditMode,
    edgeTypeDeleteQueue: state.edgeTypeDeleteQueue,
    objectTypeEditMode: state.objectTypeEditMode,
    objectTypeDeleteQueue: state.objectTypeDeleteQueue,
    location: state.location,
    query: state.query,
    routeParams: state.routeParams,
    featureFlags: state.featureFlags,
  };
})(AuthZObjectTypePage);

export default ConnectedAuthZObjectTypePage;
