import { useEffect } from 'react';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from '../store';

import { ObjectType } from '../models/authz/ObjectType';
import { SelectedTenant } from '../models/Tenant';
import EdgeType from '../models/authz/EdgeType';
import { fetchAuthZObjectTypes, fetchAuthZEdgeTypes } from '../thunks/authz';
import ObjectTable from '../controls/ObjectTable';
import PaginatedResult from '../models/PaginatedResult';

const ObjectsPage = ({
  objectTypes,
  objectTypeError,
  fetchingObjectTypes,
  edgeTypes,
  edgeTypeError,
  fetchingEdgeTypes,
  selectedTenant,
  edgeTypeEditMode,
  edgeTypeDeleteQueue,
  objectTypeEditMode,
  objectTypeDeleteQueue,
  location,
  query,
  dispatch,
}: {
  objectTypes: PaginatedResult<ObjectType> | undefined;
  objectTypeError: string;
  fetchingObjectTypes: boolean;
  edgeTypes: PaginatedResult<EdgeType> | undefined;
  edgeTypeError: string;
  fetchingEdgeTypes: boolean;
  selectedTenant: SelectedTenant | undefined;
  edgeTypeEditMode: boolean;
  edgeTypeDeleteQueue: string[];
  objectTypeEditMode: boolean;
  objectTypeDeleteQueue: string[];
  location: URL;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenant) {
      if (!objectTypes && !fetchingObjectTypes) {
        dispatch(
          fetchAuthZObjectTypes(selectedTenant.id, new URLSearchParams())
        );
      }
      if (!edgeTypes && !fetchingEdgeTypes) {
        dispatch(fetchAuthZEdgeTypes(selectedTenant.id, new URLSearchParams()));
      }
    }
  }, [
    dispatch,
    objectTypes,
    fetchingObjectTypes,
    edgeTypes,
    fetchingEdgeTypes,
    selectedTenant,
  ]);

  return (
    <ObjectTable
      selectedTenant={selectedTenant}
      objectTypes={objectTypes}
      objectTypeError={objectTypeError}
      createButton={false}
    />
  );
};

const ConnectedObjectsPage = connect((state: RootState) => {
  return {
    objectTypes: state.objectTypes,
    objectTypeError: state.fetchObjectTypesError,
    fetchingObjectTypes: state.fetchingObjectTypes,
    edgeTypes: state.edgeTypes,
    edgeTypeError: state.fetchEdgeTypesError,
    fetchingEdgeTypes: state.fetchingEdgeTypes,
    selectedTenant: state.selectedTenant,
    edgeTypeEditMode: state.edgeTypeEditMode,
    edgeTypeDeleteQueue: state.edgeTypeDeleteQueue,
    objectTypeEditMode: state.objectTypeEditMode,
    objectTypeDeleteQueue: state.objectTypeDeleteQueue,
    location: state.location,
    query: state.query,
  };
})(ObjectsPage);

export default ConnectedObjectsPage;
