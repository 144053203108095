import { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  IconAccessMethods,
  IconAccessPermissions,
  IconAccessRules,
  IconMenuCheck,
  IconMenuHome,
  IconMonitoring,
  IconSettingsGear,
  IconUserAuthentication,
  IconUserDataMapping,
  IconUserDataMasking,
  IconUserDataStorage,
  SideBarStyles as styles,
} from '@userclouds/ui-component-lib';

import { FeatureFlags } from '../models/FeatureFlag';
import { makeCleanPageLink } from '../AppNavigation';
import { RootState } from '../store';
import ServiceInfo from '../ServiceInfo';
import { SelectedTenant } from '../models/Tenant';
import Link from '../controls/Link';
import SidebarStyles from './Sidebar.module.css';
import { featureIsEnabled } from '../util/featureflags';

const pathStartsWithAnyOf = (path: string, items: string[]) => {
  return items.some((item) => {
    if (path.indexOf(item) === 0) {
      return true;
    }
    return false;
  });
};

type NavMenuStructure = {
  userDataStorageIsOpen: boolean;
  accessMethodsIsOpen: boolean;
  accessRulesIsOpen: boolean;
  accessPermissionsIsOpen: boolean;
  userDataMaskingIsOpen: boolean;
  userAuthenticationIsOpen: boolean;
  monitoringIsOpen: boolean;
  datamappingIsOpen: boolean;
};

const closedMenuStructure: NavMenuStructure = {
  userDataStorageIsOpen: false,
  accessMethodsIsOpen: false,
  accessRulesIsOpen: false,
  accessPermissionsIsOpen: false,
  userDataMaskingIsOpen: false,
  userAuthenticationIsOpen: false,
  monitoringIsOpen: false,
  datamappingIsOpen: false,
};

const SideBar = ({
  isOpen,
  handleSideBar,
  serviceInfo,
  selectedCompanyID,
  selectedTenantID,
  selectedTenant,
  location,
  featureFlags,
}: {
  isOpen: boolean;
  handleSideBar: (value: boolean) => void;
  serviceInfo: ServiceInfo | undefined;
  selectedCompanyID: string | undefined;
  selectedTenantID: string | undefined;
  selectedTenant: SelectedTenant | undefined;
  location: URL;
  featureFlags: FeatureFlags | undefined;
}) => {
  const datamappingFeatureFlag = featureIsEnabled('datamapping', featureFlags);
  const datatypeFeatureFlag = featureIsEnabled('datatype', featureFlags);
  const { pathname, search } = location;

  const [menuState, setMenuState] = useState<NavMenuStructure>({
    userDataStorageIsOpen: false,
    accessMethodsIsOpen: false,
    accessRulesIsOpen: false,
    accessPermissionsIsOpen: false,
    userDataMaskingIsOpen: false,
    userAuthenticationIsOpen: false,
    monitoringIsOpen: false,
    datamappingIsOpen: false,
  });

  const classes = clsx({
    [SidebarStyles.root]: true,
    [styles.isOpen]: isOpen,
  });

  const queryString = makeCleanPageLink(new URLSearchParams(search));

  return (
    <>
      <nav className={classes} id="mainNav">
        <ol>
          <li key="home-page">
            <Link
              href={'/' + queryString}
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathname === '/',
              })}
            >
              <IconMenuHome size="medium" />
              Tenant Home
            </Link>
          </li>
          {datamappingFeatureFlag && (
            <li key="data-mapping">
              <Link
                className={clsx({
                  [SidebarStyles.sideBarLink]: true,
                  [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                    '/datasources',
                  ]),
                })}
                onClick={() => {
                  setMenuState({
                    ...closedMenuStructure,
                    datamappingIsOpen: !menuState.datamappingIsOpen,
                  });
                }}
              >
                <IconUserDataMapping size="medium" />
                User Data Mapping
              </Link>
            </li>
          )}
          {datamappingFeatureFlag &&
            (pathStartsWithAnyOf(pathname, ['/datasources']) ||
              menuState.datamappingIsOpen) && (
              <>
                <li
                  key="data-sources"
                  className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
                >
                  <Link
                    href={'/datasources' + queryString}
                    className={clsx({
                      [SidebarStyles.sideBarLink]: true,
                      [SidebarStyles.isActive]:
                        pathStartsWithAnyOf(pathname, ['/datasources/']) ||
                        pathname === '/datasources',
                    })}
                  >
                    <span
                      className={
                        pathStartsWithAnyOf(pathname, ['/datasources/']) ||
                        pathname === '/datasources'
                          ? SidebarStyles.icon
                          : SidebarStyles.invisible
                      }
                    >
                      <IconMenuCheck size="medium" />
                    </span>
                    Data Sources
                  </Link>
                </li>
                <li
                  key="data-source-schemas"
                  className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
                >
                  <Link
                    href={'/datasourceschemas' + queryString}
                    className={clsx({
                      [SidebarStyles.sideBarLink]: true,
                      [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                        '/datasourceschemas',
                      ]),
                    })}
                  >
                    <span
                      className={
                        pathStartsWithAnyOf(pathname, ['/datasourceschemas'])
                          ? SidebarStyles.icon
                          : SidebarStyles.invisible
                      }
                    >
                      <IconMenuCheck size="medium" />
                    </span>
                    Data Source Schemas
                  </Link>
                </li>
              </>
            )}
          <li key="user-data-storage">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/columns',
                  '/users',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  userDataStorageIsOpen: !menuState.userDataStorageIsOpen,
                });
              }}
            >
              <IconUserDataStorage size="medium" />
              User Data Storage
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, [
            '/columns',
            '/users',
            '/datatypes',
            '/object_stores',
          ]) ||
            menuState.userDataStorageIsOpen) && (
            <>
              <li
                key="user-data-storage-column"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/columns' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/columns',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathname.indexOf('/columns') === 0
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Columns
                </Link>
              </li>
              <li
                key="user-data-storage-users"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/users' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/users',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathname.indexOf('/users') === 0
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Users
                </Link>
              </li>
              {datatypeFeatureFlag && (
                <li
                  key="user-data-storage-datatypes"
                  className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
                >
                  <Link
                    href={'/datatypes' + queryString}
                    className={clsx({
                      [SidebarStyles.sideBarLink]: true,
                      [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                        '/datatypes',
                      ]),
                    })}
                  >
                    <span
                      className={
                        pathname.indexOf('/datatypes') === 0
                          ? SidebarStyles.icon
                          : SidebarStyles.invisible
                      }
                    >
                      <IconMenuCheck size="medium" />
                    </span>
                    Data Types
                  </Link>
                </li>
              )}
              <li
                key="user-data-storage-object-stores"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/object_stores' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/object_stores',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathname.indexOf('/object_stores') === 0
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Object Stores
                </Link>
              </li>
            </>
          )}
          <li key="access-methods">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/accessors',
                  '/mutators',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  accessMethodsIsOpen: !menuState.accessMethodsIsOpen,
                });
              }}
            >
              <IconAccessMethods size="medium" />
              Access Methods
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, ['/accessors', '/mutators']) ||
            menuState.accessMethodsIsOpen) && (
            <>
              <li
                key="access-methods-accessors"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/accessors' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/accessors',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/accessors'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Accessors
                </Link>
              </li>
              <li
                key="access-methods-mutators"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/mutators' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/mutators',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/mutators'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Mutators
                </Link>
              </li>
            </>
          )}
          <li key="access-rules">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/purposes',
                  '/accesspolicies',
                  '/policytemplates',
                  '/secrets',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  accessRulesIsOpen: !menuState.accessRulesIsOpen,
                });
              }}
            >
              <IconAccessRules size="medium" />
              Access Rules
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, [
            '/purposes',
            '/accesspolicies',
            '/policytemplates',
            '/secrets',
          ]) ||
            menuState.accessRulesIsOpen) && (
            <>
              <li
                key="access-rules-purposes"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/purposes' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/purposes',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/purposes'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Purposes
                </Link>
              </li>
              <li
                key="access-rules-access-policies"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/accesspolicies' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/accesspolicies',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/accesspolicies'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Access Policies
                </Link>
              </li>
              <li
                key="access-rules-policy-templates"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/policytemplates' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/policytemplates',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/policytemplates'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Policy Templates
                </Link>
              </li>
              <li
                key="access-rules-secrets"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/secrets' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/secrets',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/secrets'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Secrets
                </Link>
              </li>
            </>
          )}
          <li key="access-permissions">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/organizations',
                  '/objecttypes',
                  '/edgetypes',
                  '/objects',
                  '/edges',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  accessPermissionsIsOpen: !menuState.accessPermissionsIsOpen,
                });
              }}
            >
              <IconAccessPermissions size="medium" />
              Access Permissions
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, [
            '/organizations',
            '/objecttypes',
            '/edgetypes',
            '/objects',
            '/edges',
          ]) ||
            menuState.accessPermissionsIsOpen) && (
            <>
              {selectedTenant?.use_organizations && (
                <li
                  key="access-permissions-organizations"
                  className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
                >
                  <Link
                    href={'/organizations' + queryString}
                    className={clsx({
                      [SidebarStyles.sideBarLink]: true,
                      [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                        '/organizations',
                      ]),
                    })}
                  >
                    <span
                      className={
                        pathStartsWithAnyOf(pathname, ['/organizations'])
                          ? SidebarStyles.icon
                          : SidebarStyles.invisible
                      }
                    >
                      <IconMenuCheck size="medium" />
                    </span>
                    Organizations
                  </Link>
                </li>
              )}
              <li
                key="access-permissions-object-types"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/objecttypes' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/objecttypes',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/objecttypes'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Object Types
                </Link>
              </li>
              <li
                key="access-permissions-edge-types"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/edgetypes' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/edgetypes',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/edgetypes'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Edge Types
                </Link>
              </li>
              <li
                key="access-permissions-objects"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/objects' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/objects',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/objects'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Objects
                </Link>
              </li>
              <li
                key="access-permissions-edges"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/edges' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/edges',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/edges'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Edges
                </Link>
              </li>
            </>
          )}
          <li key="user-data-masking">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/transformers',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  userDataMaskingIsOpen: !menuState.userDataMaskingIsOpen,
                });
              }}
            >
              <IconUserDataMasking size="medium" />
              User Data Masking
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, ['/transformers']) ||
            menuState.userDataMaskingIsOpen) && (
            <li
              key="user-data-masking-transformers"
              className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
            >
              <Link
                href={'/transformers' + queryString}
                className={clsx({
                  [SidebarStyles.sideBarLink]: true,
                  [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                    '/transformers',
                  ]),
                })}
              >
                <span
                  className={
                    pathStartsWithAnyOf(pathname, ['/transformers'])
                      ? SidebarStyles.icon
                      : SidebarStyles.invisible
                  }
                >
                  <IconMenuCheck size="medium" />
                </span>
                Transformers
              </Link>
            </li>
          )}
          <li key="user-authentication">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/loginapps',
                  '/commschannels',
                  '/oauthconnections',
                  '/identityproviders',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  userAuthenticationIsOpen: !menuState.userAuthenticationIsOpen,
                });
              }}
            >
              <IconUserAuthentication size="medium" />
              User Authentication
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, [
            '/loginapps',
            '/commschannels',
            '/oauthconnections',
            '/identityproviders',
          ]) ||
            menuState.userAuthenticationIsOpen) && (
            <>
              <li
                key="user-authentication-login-apps"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/loginapps' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/loginapps',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/loginapps'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Login Apps
                </Link>
              </li>
              <li
                key="user-authentication-oauth-connections"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/oauthconnections' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/oauthconnections',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/oauthconnections'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  OAuth Connections
                </Link>
              </li>
              <li
                key="user-authentication-identity-providers"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/identityproviders' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/identityproviders',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/identityproviders'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Identity Providers
                </Link>
              </li>
              <li
                key="user-authentication-comms-channels"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/commschannels' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/commschannels',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/commschannels'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Comms Channels
                </Link>
              </li>
            </>
          )}
          <li key="monitoring">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/auditlog',
                  '/dataaccesslog',
                  '/systemlog',
                  '/status',
                ]),
              })}
              onClick={() => {
                setMenuState({
                  ...closedMenuStructure,
                  monitoringIsOpen: !menuState.monitoringIsOpen,
                });
              }}
            >
              <IconMonitoring size="medium" />
              Monitoring
            </Link>
          </li>
          {(pathStartsWithAnyOf(pathname, [
            '/auditlog',
            '/dataaccesslog',
            '/systemlog',
            '/status',
          ]) ||
            menuState.monitoringIsOpen) && (
            <>
              <li
                key="monitoring-auditlog"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/auditlog' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/auditlog',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/auditlog'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Audit Log
                </Link>
              </li>
              <li
                key="monitoring-dataaccesslog"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/dataaccesslog' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/dataaccesslog',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/dataaccesslog'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Data Access Log
                </Link>
              </li>
              <li
                key="monitoring-system-log"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/systemlog' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/systemlog',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/systemlog'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  System Log
                </Link>
              </li>
              <li
                key="monitoring-status"
                className={clsx({ [SidebarStyles.sideBarSubLink]: true })}
              >
                <Link
                  href={'/status' + queryString}
                  className={clsx({
                    [SidebarStyles.sideBarLink]: true,
                    [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                      '/status',
                    ]),
                  })}
                >
                  <span
                    className={
                      pathStartsWithAnyOf(pathname, ['/status'])
                        ? SidebarStyles.icon
                        : SidebarStyles.invisible
                    }
                  >
                    <IconMenuCheck size="medium" />
                  </span>
                  Status
                </Link>
              </li>
            </>
          )}
          <li key="settings">
            <Link
              className={clsx({
                [SidebarStyles.sideBarLink]: true,
                [SidebarStyles.isActive]: pathStartsWithAnyOf(pathname, [
                  '/tenant',
                ]),
              })}
              href={'/tenants/' + selectedTenantID + queryString}
            >
              <IconSettingsGear size="medium" />
              Tenant Settings
            </Link>
          </li>
        </ol>
      </nav>
    </>
  );
};

export default connect((state: RootState) => {
  return {
    serviceInfo: state.serviceInfo,
    selectedCompanyID: state.selectedCompanyID,
    selectedTenantID: state.selectedTenantID,
    selectedTenant: state.selectedTenant,
    location: state.location,
    featureFlags: state.featureFlags,
  };
})(SideBar);
