import React from 'react';
import Styles from './DropDown.module.css';

enum DropDownOverflow {
  Left,
  Right,
}
type DropDownProps = {
  active?: boolean;
  overflow?: DropDownOverflow;
  children?: React.ReactNode;
};

const DropDown = ({ active, overflow, children }: DropDownProps) => {
  let dropDownClasses = Styles.dropdown;
  if (!active) {
    dropDownClasses += ` ${Styles.hidden}`;
  }

  if (overflow === DropDownOverflow.Left) {
    dropDownClasses += ` ${Styles.overflowleft}`;
  } else {
    dropDownClasses += ` ${Styles.overflowright}`;
  }

  return <div className={dropDownClasses}>{children}</div>;
};

DropDown.defaultProps = {
  active: false,
  overflow: DropDownOverflow.Right,
  children: null,
};

export default DropDown;
export { DropDownOverflow };
