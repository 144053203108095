import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';

import {
  useOutsideClickDetector,
  DropDown,
  DropDownOverflow,
} from '@userclouds/sharedui';

import { RootState } from '../store';
import { GetAuthURL, GetLogoutURL } from '../Auth';
import Company from '../models/Company';
import Styles from './ProfileWidget.module.css';
import { FeatureFlags } from '../models/FeatureFlag';

type ProfileWidgetProps = {
  companies: Company[] | undefined;
  selectedCompanyID: string | undefined;
  displayName: string | undefined;
  email: string | undefined;
  userID: string | undefined;
  pictureURL: string | undefined;
  impersonatorName: string | undefined;
  impersonatorUserID: string | undefined;
  impersonatorPictureURL: string | undefined;
  unimpersonateUser: () => void;
  featureFlags: FeatureFlags | undefined;
};

function onUsernameClick(userID: string) {
  // Copy username to clipboard
  const el = document.createElement('textarea');
  el.value = userID;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

const ProfileWidget = ({
  companies,
  selectedCompanyID,
  displayName,
  email,
  userID,
  pictureURL,
  impersonatorName,
  impersonatorUserID,
  impersonatorPictureURL,
  featureFlags,
  unimpersonateUser,
}: ProfileWidgetProps) => {
  const [dropDownActive, setDropDownActive] = useState(false);
  const toggleDropDown = () => {
    setDropDownActive(!dropDownActive);
  };

  const dropDownRef = useRef<HTMLDivElement>(null);
  useOutsideClickDetector([dropDownRef], () => {
    setDropDownActive(false);
  });

  const selectedCompany = (companies || []).find(
    (o) => o.id === selectedCompanyID
  );

  const widgetstyle = Styles.loginwidgetnavmenu;

  return (
    <div className={widgetstyle} ref={dropDownRef}>
      {userID ? (
        <button onClick={toggleDropDown}>
          {pictureURL && (
            <img
              key="profileimg"
              className={Styles.profileimg}
              src={pictureURL}
              alt=""
            />
          )}
        </button>
      ) : (
        <a key="signinlink" href={GetAuthURL('/')}>
          <span className={Styles.profilename}>Hello! Sign In</span>
        </a>
      )}
      <DropDown active={dropDownActive} overflow={DropDownOverflow.Left}>
        <button
          className={Styles.loginwidgetuserid}
          onClick={() => onUsernameClick(userID || '')}
        >
          <span className={Styles.loginwidgetuseridtext}>UserID: {userID}</span>
        </button>
        {selectedCompany && (
          <div className={Styles.loginwidgetcompanyid}>
            <span className={Styles.loginwidgetcompanyidtext}>
              Company: {selectedCompany.name}
            </span>
          </div>
        )}
        <a className={Styles.logouttext} href={GetLogoutURL('/')}>
          Logout
        </a>
        {impersonatorUserID && (
          <button
            className={Styles.loginwidgetimpersonationbutton}
            onClick={unimpersonateUser}
          >
            Impersonator: {impersonatorName} ({impersonatorUserID})
          </button>
        )}
      </DropDown>
    </div>
  );
};

export default connect((state: RootState) => ({
  companies: state.companies,
  selectedCompanyID: state.selectedCompanyID,
  featureFlags: state.featureFlags,
}))(ProfileWidget);
