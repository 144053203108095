import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  Checkbox,
  InlineNotification,
  Label,
  TextInput,
  Select,
} from '@userclouds/ui-component-lib';

import { redirect } from '../routing';
import API from '../API';
import { createTenant } from '../API/tenants';
import fetchServiceInfo from '../thunks/FetchServiceInfo';
import actions from '../actions';
import { RootState, AppDispatch } from '../store';
import Company, { CompanyType, DefaultCompanyType } from '../models/Company';
import { SelectedTenant, TenantState } from '../models/Tenant';
import { GetLogoutURL } from '../Auth';
import WelcomePageStyles from './WelcomePage.module.css';
import Region, { PrimaryRegion } from '../models/Region';

type CompanyAndTenant = {
  company: Company;
  tenant: SelectedTenant;
};

// Helper method that returns either `CompanyAndTenant` (on success) or a string error message string (on failure).
const createCompanyAndTenant =
  (
    companyName: string,
    companyType: CompanyType,
    tenantName: string,
    useOrgs: boolean,
    primaryRegion: string
  ) =>
  (dispatch: AppDispatch): Promise<CompanyAndTenant> => {
    return new Promise((resolve, reject) => {
      let createdCompany: Company | undefined;
      let createdTenant: SelectedTenant | undefined;
      const companyID = uuidv4();
      dispatch({
        type: actions.CREATE_COMPANY_REQUEST,
      });
      return API.createCompany({
        id: companyID,
        name: companyName,
        type: companyType,
        is_admin: true,
      })
        .then(
          (company: Company) => {
            createdCompany = company;
            dispatch({
              type: actions.CREATE_COMPANY_SUCCESS,
              data: company,
            });
            // https://github.com/userclouds/userclouds/issues/904
            //
            // serviceinfo response includes list of companies for which
            // user is an admin. We need to refetch it after a new tenant creation
            dispatch(fetchServiceInfo());
            return createTenant(
              company.id,
              {
                id: uuidv4(),
                name: tenantName,
                company_id: company.id,
                use_organizations: useOrgs,
                primary_region: primaryRegion,
                state: TenantState.CREATING,
              },
              false
            );
          },
          (error) => {
            reject(`Error creating company ${companyName}: ${error.message}`);
          }
        )
        .then(
          (tenant: SelectedTenant | void) => {
            if (tenant) {
              createdTenant = tenant;
              resolve({
                company: createdCompany as Company,
                tenant: createdTenant,
              });
            } else {
              reject(
                `Company ${
                  (createdCompany as Company).name
                } created but an error occurred while creating tenant ${tenantName}`
              );
            }
          },
          (error) => {
            reject(
              `Company ${
                (createdCompany as Company).name
              } created but an error occurred while creating tenant ${tenantName}: ${
                error.message
              }`
            );
          }
        );
    });
  };

const WelcomePage = ({
  name,
  userID,
  location,
  dispatch,
}: {
  name: string;
  userID: string;
  location: URL;
  dispatch: AppDispatch;
}) => {
  const heading = `Welcome to UserClouds, ${name}!`;
  const subHeading = `Let's get you set up with a new company.`;
  const goButtonText = `Let's go!`;
  const notYouButtonText = 'Not You?';
  const [companyName, setCompanyName] = useState<string>();
  const [companyType, setCompanyType] = useState<CompanyType>();
  const [tenantName, setTenantName] = useState<string>();
  const [useOrgs, setUseOrgs] = useState<boolean>(false);
  const [statusText, setStatusText] = useState<string>('');
  const [statusError, setStatusError] = useState<boolean>(false);
  const [primaryRegion, setPrimaryRegion] = useState<Region>(PrimaryRegion);

  const inputInvalid = !companyName || !tenantName;
  const disallowSubmit = inputInvalid || statusText !== '';

  const onSubmit = async () => {
    if (disallowSubmit) {
      return;
    }

    setStatusText(
      `Creating company '${companyName}' and tenant '${tenantName}'...`
    );
    setStatusError(false);

    // Create company, then tenant, then force re-fetch all companies to ensure UI has latest data & redirect to new tenant.
    dispatch(
      createCompanyAndTenant(
        companyName,
        companyType || DefaultCompanyType,
        tenantName,
        useOrgs,
        primaryRegion
      )
    ).then(
      (result: CompanyAndTenant) => {
        const { company, tenant } = result;
        setStatusText(
          `Created company '${company.name}' and tenant '${tenant.name}', redirecting to tenant page...`
        );
        setStatusError(false);
        redirect(`/?company_id=${company.id}&tenant_id=${tenant.id}`, true);
      },
      (error: string) => {
        setStatusText(error);
        setStatusError(true);
      }
    );
  };

  return (
    <div className={WelcomePageStyles.welcomepage}>
      <Card title={heading} description={subHeading}>
        <Label>
          Company
          <br />
          <TextInput
            id="company_name"
            name="company_name"
            value={companyName || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value;
              setCompanyName(val);
            }}
          />
        </Label>
        <Label>
          Company type
          <br />
          <Select
            name="company_type"
            defaultValue="internal"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value;
              setCompanyType(val as CompanyType);
            }}
          >
            {Object.values(CompanyType).map((ct: CompanyType) => (
              <option value={ct} key={ct}>
                {ct}
              </option>
            ))}
          </Select>
        </Label>
        <Label>
          Tenant
          <br />
          <TextInput
            label="Tenant"
            id="tenant_name"
            name="tenant_name"
            value={tenantName || ''}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value;
              setTenantName(val);
            }}
          />
        </Label>
        <Checkbox
          checked={useOrgs}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { checked } = e.target;
            setUseOrgs(checked);
          }}
        >
          Use organizations
        </Checkbox>
        <Label>
          Primary region
          <br />
          <Select
            name="primary_region"
            defaultValue={PrimaryRegion}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const val = e.target.value;
              setPrimaryRegion(val as Region);
            }}
          >
            {Object.values(Region).map((region: Region) => (
              <option value={region} key={region}>
                {region}
              </option>
            ))}
          </Select>
        </Label>
        <Button disabled={disallowSubmit} onClick={() => onSubmit()}>
          {goButtonText}
        </Button>
        <a className={WelcomePageStyles.notyoulink} href={GetLogoutURL('/')}>
          {notYouButtonText}
        </a>
        <div className={WelcomePageStyles.userid}>{
          // TODO: get rid of userID here; it's useful for now to figure out your UUID
          // so you can make yourself an company admin as needed via make-company-admin.sh
          `Your user ID is ${userID}`
        }</div>
        {statusText ? (
          <InlineNotification theme={statusError ? 'alert' : 'success'}>
            {statusText}
          </InlineNotification>
        ) : (
          ''
        )}
      </Card>
    </div>
  );
};

export default connect((state: RootState) => ({
  location: state.location,
}))(WelcomePage);
