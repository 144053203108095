import React from 'react';
import { IconTypes, getSize } from '../iconHelpers';

function IconMonitoring({
  className,
  size = 'medium',
}: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      className={className}
      fill="currentColor"
    >
      <title>Monitoring</title>
      <path
        d="M10 1.66663C14.6025 1.66663 18.3334 5.39746 18.3334 9.99996C18.3334 14.6025 14.6025 18.3333 10 18.3333C5.39752 18.3333 1.66669 14.6025 1.66669 9.99996C1.66669 5.39746 5.39752 1.66663 10 1.66663ZM13.83 6.16996C13.66 5.99913 13.3917 5.97579 13.1942 6.11413C10.7859 7.80413 9.42752 8.80579 9.11585 9.11579C8.62835 9.60413 8.62835 10.3958 9.11585 10.8841C9.60419 11.3716 10.3959 11.3716 10.8842 10.8841C11.0667 10.7008 12.0659 9.34163 13.8825 6.80329C14.0225 6.60829 14 6.33996 13.83 6.16996ZM14.5834 9.16663C14.1234 9.16663 13.75 9.53996 13.75 9.99996C13.75 10.46 14.1234 10.8333 14.5834 10.8333C15.0434 10.8333 15.4167 10.46 15.4167 9.99996C15.4167 9.53996 15.0434 9.16663 14.5834 9.16663ZM5.41669 9.16663C4.95669 9.16663 4.58335 9.53996 4.58335 9.99996C4.58335 10.46 4.95669 10.8333 5.41669 10.8333C5.87669 10.8333 6.25002 10.46 6.25002 9.99996C6.25002 9.53996 5.87669 9.16663 5.41669 9.16663ZM7.34835 6.16996C7.02335 5.84496 6.49502 5.84496 6.17002 6.16996C5.84502 6.49496 5.84502 7.02246 6.17002 7.34829C6.49502 7.67329 7.02252 7.67329 7.34835 7.34829C7.67335 7.02329 7.67335 6.49496 7.34835 6.16996ZM10 4.58329C9.54002 4.58329 9.16669 4.95663 9.16669 5.41663C9.16669 5.87663 9.54002 6.24996 10 6.24996C10.46 6.24996 10.8334 5.87663 10.8334 5.41663C10.8334 4.95663 10.46 4.58329 10 4.58329Z"
        fill="#283354"
      />{' '}
    </svg>
  );
}

export default IconMonitoring;
