import React from 'react';
import { Chart } from 'react-google-charts';
import { LoaderDots } from '@userclouds/ui-component-lib';
import { ChartDataSerialized } from './ChartData';

interface ChartElementProps {
  data: ChartDataSerialized | undefined;
  isFetching: boolean;
  className: string;
  divId: string;
  chartTitle: string;
  yAxisTitle: string;
  index: number;
  yAxisPeriod: number;
  labels: string[];
}

const ChartElement = ({
  data,
  isFetching,
  className,
  divId,
  chartTitle,
  yAxisTitle,
  index,
  yAxisPeriod,
  labels,
}: ChartElementProps) => {
  if (data) {
    // Update labels on the x-axis and convert them to "string" so that library doesn't reorder them
    data.cols[0].type = 'string';
    for (let i = 0; i < data.rows.length; i += 1) {
      data.rows[i].c[0].v = (data.rows.length - i) * yAxisPeriod;
    }
    // Update names of the labels
    for (let i = 0; i < labels.length; i += 1) {
      data.cols[i + 1].label = labels[i];
    }
  } else {
    return (
      <div id={divId} className={className}>
        <LoaderDots assistiveText="Loading ..." size="small" theme="brand" />
      </div>
    );
  }
  return (
    <div id={divId} className={className}>
      <Chart
        width={540}
        height={400}
        chartType="LineChart"
        loader={
          <LoaderDots assistiveText="Loading ..." size="small" theme="brand" />
        }
        data={data}
        options={{
          title: chartTitle,
          hAxis: {
            title: yAxisTitle,
            minValue: 0,
          },
          vAxis: {
            title: 'Count',
            format: 'short',
            minValue: 0,
          },
        }}
        legendToggle
      />
    </div>
  );
};

export default ChartElement;
