import { APIError, JSONValue } from '@userclouds/sharedui';

import Accessor, {
  AccessorColumn,
  ExecuteAccessorResponse,
} from '../models/Accessor';
import PaginatedResult from '../models/PaginatedResult';
import Purpose from '../models/Purpose';

export const CHANGE_ACCESSOR_SEARCH_FILTER = 'CHANGE_ACCESSOR_SEARCH_FILTER';
export const CHANGE_ACCESSOR_LIST_INCLUDE_AUTOGENERATED =
  'CHANGE_ACCESSOR_LIST_INCLUDE_AUTOGENERATED';
export const TOGGLE_ACCESSOR_LIST_EDIT_MODE = 'TOGGLE_ACCESSOR_LIST_EDIT_MODE';
export const TOGGLE_ACCESSOR_FOR_DELETE = 'TOGGLE_ACCESSOR_FOR_DELETE';
export const DELETE_ACCESSOR_SUCCESS = 'DELETE_ACCESSOR_SUCCESS';
export const DELETE_ACCESSOR_ERROR = 'DELETE_ACCESSOR_ERROR';
export const BULK_EDIT_ACCESSORS_REQUEST = 'BULK_EDIT_ACCESSORS_REQUEST';
export const BULK_EDIT_ACCESSORS_SUCCESS = 'BULK_EDIT_ACCESSORS_SUCCESS';
export const BULK_EDIT_ACCESSORS_ERROR = 'BULK_EDIT_ACCESSORS_ERROR';
export const GET_TENANT_ACCESSORS_REQUEST = 'GET_TENANT_ACCESSORS_REQUEST';
export const GET_TENANT_ACCESSORS_SUCCESS = 'GET_TENANT_ACCESSORS_SUCCESS';
export const GET_TENANT_ACCESSORS_ERROR = 'GET_TENANT_ACCESSORS_ERROR';
export const GET_ACCESSOR_REQUEST = 'GET_ACCESSOR_REQUEST';
export const GET_ACCESSOR_SUCCESS = 'GET_ACCESSOR_SUCCESS';
export const GET_ACCESSOR_ERROR = 'GET_ACCESSOR_ERROR';
export const MODIFY_ACCESSOR_DETAILS = 'MODIFY_ACCESSOR_DETAILS';
export const REMOVE_PURPOSE_FROM_ACCESSOR = 'REMOVE_PURPOSE_FROM_ACCESSOR';
export const ADD_PURPOSE_TO_ACCESSOR = 'ADD_PURPOSE_TO_ACCESSOR';
export const MODIFY_ACCESSOR_TO_CREATE = 'MODIFY_ACCESSOR_TO_CREATE';
export const MODIFY_COLUMN_IN_ACCESSOR_TO_CREATE =
  'MODIFY_COLUMN_IN_ACCESSOR_TO_CREATE';
export const REMOVE_PURPOSE_FROM_ACCESSOR_TO_CREATE =
  'REMOVE_PURPOSE_FROM_ACCESSOR_TO_CREATE';
export const ADD_PURPOSE_TO_ACCESSOR_TO_CREATE =
  'ADD_PURPOSE_TO_ACCESSOR_TO_CREATE';
export const TOGGLE_ACCESSOR_DETAILS_EDIT_MODE =
  'TOGGLE_ACCESSOR_DETAILS_EDIT_MODE';
export const TOGGLE_ACCESSOR_COLUMNS_EDIT_MODE =
  'TOGGLE_ACCESSOR_COLUMNS_EDIT_MODE';
export const TOGGLE_ACCESSOR_EDIT_MODE = 'TOGGLE_ACCESSOR_EDIT_MODE';
export const UPDATE_ACCESSOR_DETAILS_REQUEST =
  'UPDATE_ACCESSOR_DETAILS_REQUEST';
export const UPDATE_ACCESSOR_DETAILS_SUCCESS =
  'UPDATE_ACCESSOR_DETAILS_SUCCESS';
export const UPDATE_ACCESSOR_DETAILS_ERROR = 'UPDATE_ACCESSOR_DETAILS_ERROR';
export const ADD_ACCESSOR_COLUMN = 'ADD_ACCESSOR_COLUMN';
export const TOGGLE_ACCESSOR_COLUMN_FOR_DELETE =
  'TOGGLE_ACCESSOR_COLUMN_FOR_DELETE';
export const CHANGE_ACCESSOR_ADD_COLUMN_DROPDOWN_VALUE =
  'CHANGE_ACCESSOR_ADD_COLUMN_DROPDOWN_VALUE';
export const SAVE_ACCESSOR_COLUMNS_CONFIGURATION_REQUEST =
  'SAVE_ACCESSOR_COLUMNS_CONFIGURATION_REQUEST';
export const SAVE_ACCESSOR_COLUMNS_CONFIGURATION_SUCCESS =
  'SAVE_ACCESSOR_COLUMNS_CONFIGURATION_SUCCESS';
export const SAVE_ACCESSOR_COLUMNS_CONFIGURATION_ERROR =
  'SAVE_ACCESSOR_COLUMNS_CONFIGURATION_ERROR';

export const CHANGE_SELECTED_ACCESS_POLICY_FOR_ACCESSOR =
  'CHANGE_SELECTED_ACCESS_POLICY_FOR_ACCESSOR';
export const CHANGE_SELECTED_TRANSFORMER_FOR_COLUMN =
  'CHANGE_SELECTED_TRANSFORMER_FOR_COLUMN';
export const CHANGE_SELECTED_TOKEN_ACCESS_POLICY_FOR_COLUMN =
  'CHANGE_SELECTED_TOKEN_ACCESS_POLICY_FOR_COLUMN';
export const UPDATE_ACCESSOR_POLICIES_REQUEST =
  'UPDATE_ACCESSOR_POLICIES_REQUEST';
export const UPDATE_ACCESSOR_POLICIES_SUCCESS =
  'UPDATE_ACCESSOR_POLICIES_SUCCESS';
export const UPDATE_ACCESSOR_POLICIES_ERROR = 'UPDATE_ACCESSOR_POLICIES_ERROR';
export const LOAD_CREATE_ACCESSOR_PAGE = 'LOAD_CREATE_ACCESSOR_PAGE';
export const CREATE_ACCESSOR_REQUEST = 'CREATE_ACCESSOR_REQUEST';
export const CREATE_ACCESSOR_SUCCESS = 'CREATE_ACCESSOR_SUCCESS';
export const CREATE_ACCESSOR_ERROR = 'CREATE_ACCESSOR_ERROR';
export const EXECUTE_ACCESSOR_CHANGE_CONTEXT =
  'EXECUTE_ACCESSOR_CHANGE_CONTEXT';
export const EXECUTE_ACCESSOR_CHANGE_SELECTOR_VALUES =
  'EXECUTE_ACCESSOR_CHANGE_SELECTOR_VALUES';
export const EXECUTE_ACCESSOR_SUCCESS = 'EXECUTE_ACCESSOR_SUCCESS';
export const EXECUTE_ACCESSOR_ERROR = 'EXECUTE_ACCESSOR_ERROR';
export const EXECUTE_ACCESSOR_RESET = 'EXECUTE_ACCESSOR_RESET';

export const changeAccessorSearchFilter = (
  changes: Record<string, string>
) => ({
  type: CHANGE_ACCESSOR_SEARCH_FILTER,
  data: changes,
});

export const toggleAccessorListIncludeAutogenerated = (include: boolean) => ({
  type: CHANGE_ACCESSOR_LIST_INCLUDE_AUTOGENERATED,
  data: include,
});

export const toggleAccessorListEditMode = (editMode?: boolean) => ({
  type: TOGGLE_ACCESSOR_LIST_EDIT_MODE,
  data: editMode,
});

export const toggleAccessorForDelete = (accessor: Accessor) => ({
  type: TOGGLE_ACCESSOR_FOR_DELETE,
  data: accessor,
});

export const bulkEditAccessorsRequest = () => ({
  type: BULK_EDIT_ACCESSORS_REQUEST,
});

export const bulkEditAccessorsSuccess = () => ({
  type: BULK_EDIT_ACCESSORS_SUCCESS,
});

export const bulkEditAccessorsError = () => ({
  type: BULK_EDIT_ACCESSORS_ERROR,
});

export const deleteAccessorSuccess = (accessorID: string) => ({
  type: DELETE_ACCESSOR_SUCCESS,
  data: accessorID,
});

export const deleteAccessorError = (error: APIError) => ({
  type: DELETE_ACCESSOR_ERROR,
  data: error.message,
});

export const getTenantAccessorsRequest = () => ({
  type: GET_TENANT_ACCESSORS_REQUEST,
});

export const getTenantAccessorsSuccess = (
  accessors: PaginatedResult<Accessor>
) => ({
  type: GET_TENANT_ACCESSORS_SUCCESS,
  data: accessors,
});

export const getTenantAccessorsError = (error: APIError) => ({
  type: GET_TENANT_ACCESSORS_ERROR,
  data: error.message,
});

export const getAccessorRequest = (accessorID: string) => ({
  type: GET_ACCESSOR_REQUEST,
});

export const getAccessorSuccess = (accessor: Accessor) => ({
  type: GET_ACCESSOR_SUCCESS,
  data: accessor,
});

export const getAccessorError = (error: APIError) => ({
  type: GET_ACCESSOR_ERROR,
  data: error.message,
});

export const toggleAccessorDetailsEditMode = (editMode?: boolean) => ({
  type: TOGGLE_ACCESSOR_DETAILS_EDIT_MODE,
  data: editMode,
});

export const modifyAccessorDetails = (data: Record<string, JSONValue>) => ({
  type: MODIFY_ACCESSOR_DETAILS,
  data,
});

export const removePurposeFromAccessor = (purpose: Purpose) => ({
  type: REMOVE_PURPOSE_FROM_ACCESSOR,
  data: purpose,
});

export const addPurposeToAccessor = (purposeID: string) => ({
  type: ADD_PURPOSE_TO_ACCESSOR,
  data: purposeID,
});

export const modifyAccessorToCreate = (data: Record<string, JSONValue>) => ({
  type: MODIFY_ACCESSOR_TO_CREATE,
  data,
});

export const modifyColumnInAccessorToCreate = (
  columnID: string,
  columnData: Record<string, JSONValue>
) => ({
  type: MODIFY_COLUMN_IN_ACCESSOR_TO_CREATE,
  data: {
    columnID,
    columnData,
  },
});

export const removePurposeFromAccessorToCreate = (purpose: Purpose) => ({
  type: REMOVE_PURPOSE_FROM_ACCESSOR_TO_CREATE,
  data: purpose,
});

export const addPurposeToAccessorToCreate = (purposeID: string) => ({
  type: ADD_PURPOSE_TO_ACCESSOR_TO_CREATE,
  data: purposeID,
});

export const updateAccessorDetailsRequest = () => ({
  type: UPDATE_ACCESSOR_DETAILS_REQUEST,
});

export const updateAccessorDetailsSuccess = (accessor: Accessor) => ({
  type: UPDATE_ACCESSOR_DETAILS_SUCCESS,
  data: accessor,
});

export const updateAccessorDetailsError = (error: APIError) => ({
  type: UPDATE_ACCESSOR_DETAILS_ERROR,
  data: error.message,
});

export const toggleAccessorColumnsEditMode = (editMode?: boolean) => ({
  type: TOGGLE_ACCESSOR_COLUMNS_EDIT_MODE,
  data: editMode,
});

export const toggleAccessorEditMode = (editMode?: boolean) => ({
  type: TOGGLE_ACCESSOR_EDIT_MODE,
  data: editMode,
});

export const toggleAccessorColumnForDelete = (column: AccessorColumn) => ({
  type: TOGGLE_ACCESSOR_COLUMN_FOR_DELETE,
  data: column,
});

export const changeAccessorAddColumnDropdownValue = (value: string) => ({
  type: CHANGE_ACCESSOR_ADD_COLUMN_DROPDOWN_VALUE,
  data: value,
});

export const addAccessorColumn = (value: string) => ({
  type: ADD_ACCESSOR_COLUMN,
  data: value,
});

export const changeSelectedTransformerForColumn = (
  columnID: string,
  transformerID: string,
  isNew: boolean
) => ({
  type: CHANGE_SELECTED_TRANSFORMER_FOR_COLUMN,
  data: {
    columnID,
    transformerID,
    isNew,
  },
});

export const changeSelectedTokenAccessPolicyForColumn = (
  columnID: string,
  policyID: string,
  isNew: boolean
) => ({
  type: CHANGE_SELECTED_TOKEN_ACCESS_POLICY_FOR_COLUMN,
  data: {
    columnID,
    policyID,
    isNew,
  },
});

export const saveAccessorColumnsConfigurationRequest = () => ({
  type: SAVE_ACCESSOR_COLUMNS_CONFIGURATION_REQUEST,
});

export const saveAccessorColumnsConfigurationSuccess = () => ({
  type: SAVE_ACCESSOR_COLUMNS_CONFIGURATION_SUCCESS,
});

export const saveAccessorColumnsConfigurationError = (error: APIError) => ({
  type: SAVE_ACCESSOR_COLUMNS_CONFIGURATION_ERROR,
  data: error.message,
});

export const changeSelectedAccessPolicyForAccessor = (policyID: string) => ({
  type: CHANGE_SELECTED_ACCESS_POLICY_FOR_ACCESSOR,
  data: policyID,
});

export const loadCreateAccessorPage = () => ({
  type: LOAD_CREATE_ACCESSOR_PAGE,
});

export const createAccessorRequest = () => ({
  type: CREATE_ACCESSOR_REQUEST,
});

export const createAccessorSuccess = (accessor: Accessor) => ({
  type: CREATE_ACCESSOR_SUCCESS,
  data: accessor,
});

export const createAccessorError = (error: APIError) => ({
  type: CREATE_ACCESSOR_ERROR,
  data: error.message,
});

export const changeExecuteAccessorContext = (value: string) => ({
  type: EXECUTE_ACCESSOR_CHANGE_CONTEXT,
  data: value,
});

export const changeExecuteAccessorSelectorValues = (value: string) => ({
  type: EXECUTE_ACCESSOR_CHANGE_SELECTOR_VALUES,
  data: value,
});

export const executeAccessorReset = () => ({
  type: EXECUTE_ACCESSOR_RESET,
});

export const executeAccessorSuccess = (
  value: ExecuteAccessorResponse,
  piiFields: string[],
  sensitiveFields: string[]
) => ({
  type: EXECUTE_ACCESSOR_SUCCESS,
  data: {
    resp: value,
    piiFields,
    sensitiveFields,
  },
});

export const executeAccessorError = (error: APIError) => ({
  type: EXECUTE_ACCESSOR_ERROR,
  data: error.message,
});
