import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  Card,
  CardRow,
  InlineNotification,
  InputReadOnly,
  Label,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableRowHead,
  Text,
  ToolTip,
} from '@userclouds/ui-component-lib';
import { APIError } from '@userclouds/sharedui';

import API from '../API';
import actions from '../actions';
import { RootState, AppDispatch } from '../store';
import ServiceInfo from '../ServiceInfo';
import ChartData from '../chart/ChartData';
import ChartQuery from '../chart/ChartQuery';
import ChartElement from '../chart/ChartElement';
import ActiveInstance from '../chart/ActiveInstance';
import LogRow from '../chart/LogRow';
import serviceChartMetadata, {
  AllChartsMetadata,
  ChartRenderingDetails,
} from '../chart/ChartMetadataPerService';
import StatusPageStyles from './StatusPage.module.css';
import { FeatureFlags } from '../models/FeatureFlag';

import PageCommon from './PageCommon.module.css';

const fetchActiveInstances = (tenantID: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: actions.GET_ACTIVE_INSTANCES_REQUEST,
  });
  API.fetchActiveInstances(tenantID).then(
    (instances: ActiveInstance[]) => {
      dispatch({
        type: actions.GET_ACTIVE_INSTANCES_SUCCESS,
        data: instances,
      });
    },
    (error: APIError) => {
      dispatch({
        type: actions.GET_ACTIVE_INSTANCES_ERROR,
        data: error.message,
      });
    }
  );
};

const fetchLogEvents = (tenantID: string) => (dispatch: AppDispatch) => {
  dispatch({
    type: actions.GET_EVENT_LOG_REQUEST,
  });
  API.fetchEventLog(tenantID).then(
    (events: LogRow[]) => {
      dispatch({
        type: actions.GET_EVENT_LOG_SUCCESS,
        data: events,
      });
    },
    (error: APIError) => {
      dispatch({
        type: actions.GET_EVENT_LOG_ERROR,
        data: error.message,
      });
    }
  );
};

const fetchChartData =
  (tenantID: string, queries: ChartQuery[]) => (dispatch: AppDispatch) => {
    dispatch({
      type: actions.GET_CHART_DATA_REQUEST,
    });
    API.fetchChartData(tenantID, queries).then(
      (data: ChartData) => {
        dispatch({
          type: actions.GET_CHART_DATA_SUCCESS,
          data,
        });
      },
      (error: APIError) => {
        dispatch({
          type: actions.GET_CHART_DATA_ERROR,
          data: error.message,
        });
      }
    );
  };

const InfraInstanceList = ({
  selectedTenantID,
  serviceInfo,
  activeInstances,
  isFetching,
  fetchError,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  serviceInfo: ServiceInfo | undefined;
  activeInstances: ActiveInstance[] | undefined;
  isFetching: boolean;
  fetchError: string;
  dispatch: AppDispatch;
}) => {
  if (fetchError) {
    return <InlineNotification theme="alert">{fetchError}</InlineNotification>;
  }

  if (!serviceInfo || !serviceInfo.uc_admin) {
    return <Text>Service is operating normally</Text>;
  }

  return (
    <Card title="Infrastructure Resources">
      <Table>
        <TableHead>
          <TableRow key="heading">
            <TableRowHead>Instance ID</TableRowHead>
            <TableRowHead>Service</TableRowHead>
            <TableRowHead>Startup Time</TableRowHead>
            <TableRowHead>Last Active Time</TableRowHead>
            <TableRowHead>Event Count</TableRowHead>
            <TableRowHead>Input Errors</TableRowHead>
            <TableRowHead>System Errors</TableRowHead>
            <TableRowHead>Status</TableRowHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {activeInstances?.length ? (
            activeInstances.map((instance) => (
              <TableRow key={`instance_${instance.instance_id}`}>
                <TableCell>
                  <InputReadOnly monospace>
                    {instance.instance_id}
                  </InputReadOnly>
                </TableCell>
                <TableCell>{instance.service}</TableCell>
                <TableCell>
                  {new Date(instance.startup_time).toLocaleString('en-US')}
                </TableCell>
                <TableCell>
                  {new Date(instance.last_activity).toLocaleString('en-US')}
                </TableCell>
                <TableCell>{instance.event_count}</TableCell>
                <TableCell>{instance.error_input_count}</TableCell>
                <TableCell>{instance.error_internal_count}</TableCell>
                <TableCell>Healthy</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="8">
                {isFetching
                  ? 'No active infra resources found for this tenant'
                  : 'Loading ...'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

const ConnectedInstanceList = connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  serviceInfo: state.serviceInfo,
  activeInstances: state.activeInstances,
  isFetching: state.fetchingActiveInstances,
  fetchError: state.activeInstancesFetchError,
}))(InfraInstanceList);

const EventLogList = ({
  selectedTenantID,
  serviceInfo,
  logEvents,
  isFetching,
  fetchError,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  serviceInfo: ServiceInfo | undefined;
  logEvents: LogRow[] | undefined;
  isFetching: boolean;
  fetchError: string;
  dispatch: AppDispatch;
}) => {
  if (!serviceInfo || !serviceInfo.uc_admin) {
    return <></>;
  }

  if (fetchError) {
    return <InlineNotification theme="alert">{fetchError}</InlineNotification>;
  }

  return (
    <Card title="Recent Events">
      <Table>
        <TableHead>
          <TableRow key="heading">
            <TableRowHead>Time</TableRowHead>
            <TableRowHead>Name</TableRowHead>
            <TableRowHead>Type</TableRowHead>
            <TableRowHead>Service</TableRowHead>
            <TableRowHead>Count</TableRowHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {logEvents?.length ? (
            logEvents.map((logEvent) => (
              <TableRow key={`event_${logEvent.id}`}>
                <TableCell>
                  {new Date(logEvent.timestamp).toLocaleString('en-US')}
                </TableCell>
                <TableCell>{logEvent.event_name}</TableCell>
                <TableCell>{logEvent.event_type}</TableCell>
                <TableCell>{logEvent.service}</TableCell>
                <TableCell>{logEvent.count}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan="5">
                {isFetching ? 'No events found for this tenant' : 'Loading ...'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
};

const ConnectedEventLogList = connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  serviceInfo: state.serviceInfo,
  logEvents: state.logEvents,
  isFetching: state.fetchingActiveInstances,
  fetchError: state.activeInstancesFetchError,
}))(EventLogList);

// TODO: model layer somewhere
const DetermineLabelAndPeriod = (timePeriod: string) => {
  let timeSelection = 'Minutes Back';
  let labelPeriod = 1;

  switch (timePeriod) {
    case 'hour':
      labelPeriod = 5;
      break;
    case 'day':
      timeSelection = 'Hours Back';
      break;
    case 'week':
      timeSelection = 'Days Back';
      break;
    default: /* 'minutes' */
  }
  return { timeSelection, labelPeriod };
};

const buildChartQueries = (
  tenantID: string,
  timePeriod: string,
  service: string
) => {
  const { queries } = (serviceChartMetadata as AllChartsMetadata)[service];
  queries.forEach((query: ChartQuery) => {
    query.setTarget(tenantID, service);
    query.setTimePeriod(timePeriod);
  });

  return queries;
};
const ChartGrid = ({
  selectedTenantID,
  chartData,
  isFetching,
  fetchError,
  service,
  timePeriod,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  chartData: ChartData | undefined;
  isFetching: boolean;
  fetchError: string;
  service: string;
  timePeriod: string;
  dispatch: AppDispatch;
}) => {
  // TODO: redux for this
  const [endTime, setEndTime] = useState<string>('');
  const { timeSelection, labelPeriod } = DetermineLabelAndPeriod(timePeriod);
  const { charts } = (serviceChartMetadata as AllChartsMetadata)[service];

  useEffect(() => {
    const endDate = new Date();
    setEndTime(endDate.toLocaleString('en-US'));
  }, [timePeriod]);

  return (
    <Card title="Metrics" className={StatusPageStyles.metrics}>
      {fetchError && (
        <InlineNotification theme="alert">{fetchError}</InlineNotification>
      )}
      <div id="config" className={StatusPageStyles.selector_row}>
        <Label>
          Metrics for:
          <Select
            name="Service"
            value={service}
            onChange={(event: React.ChangeEvent) => {
              if (!selectedTenantID) {
                return;
              }
              const newService = (event.target as HTMLSelectElement).value;
              dispatch(
                fetchChartData(
                  selectedTenantID,
                  buildChartQueries(selectedTenantID, timePeriod, newService)
                )
              );
              // TODO: we're launching this and the GET_CHART_DATA_REQUEST action
              dispatch({
                type: actions.CHANGE_CHART_DATA_SERVICE,
                data: newService,
              });
            }}
          >
            <option value="plex">Authentication</option>
            <option value="idp">Identity Provider</option>
            <option value="authz">Authorization</option>
            <option value="tokenizer">Tokenization</option>
            <option value="console">Administrative</option>
          </Select>
        </Label>
        <Label>
          Time period:
          <Select
            value={timePeriod}
            onChange={(event: React.ChangeEvent) => {
              if (!selectedTenantID) {
                return;
              }
              const newTimePeriod = (event.target as HTMLSelectElement).value;
              dispatch(
                fetchChartData(
                  selectedTenantID,
                  buildChartQueries(selectedTenantID, newTimePeriod, service)
                )
              );
              // TODO: we're launching this and the GET_CHART_DATA_REQUEST action
              dispatch({
                type: actions.CHANGE_CHART_DATA_TIME_PERIOD,
                data: newTimePeriod,
              });
            }}
          >
            <option value="minutes">Last 10 minutes</option>
            <option value="hour">Last hour</option>
            <option value="day">Last day</option>
            <option value="week">Last week</option>
          </Select>
        </Label>
        <Label>
          Displayed Up To: {endTime}
          <Button
            type="button"
            theme="secondary"
            onClick={() => {
              if (!selectedTenantID) {
                return;
              }
              const endDate = new Date();
              setEndTime(endDate.toLocaleString('en-US'));
              dispatch(
                fetchChartData(
                  selectedTenantID,
                  buildChartQueries(selectedTenantID, timePeriod, service)
                )
              );
            }}
          >
            Refresh
          </Button>
        </Label>
      </div>
      <CardRow>
        {charts?.length ? (
          charts.map((chart: ChartRenderingDetails, i) => (
            <ChartElement
              key={chart.divId}
              isFetching={isFetching}
              data={chartData?.data[i]}
              className={chart.className}
              divId={chart.divId}
              chartTitle={chart.title}
              yAxisTitle={timeSelection}
              index={i}
              yAxisPeriod={labelPeriod}
              labels={chart.labels}
            />
          ))
        ) : (
          <Text>
            {isFetching ? 'Loading ...' : 'No data to display for this service'}
          </Text>
        )}
      </CardRow>
    </Card>
  );
};

const ConnectedChartGrid = connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  serviceInfo: state.serviceInfo,
  chartData: state.chartData,
  isFetching: state.fetchingChartData,
  service: state.chartDataService,
  timePeriod: state.chartDataTimePeriod,
  fetchError: state.chartDataFetchError,
}))(ChartGrid);

const StatusPage = ({
  selectedTenantID,
  service,
  timePeriod,
  location,
  query,
  featureFlags,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  service: string;
  timePeriod: string;
  location: URL;
  query: URLSearchParams;
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchActiveInstances(selectedTenantID));
      dispatch(fetchLogEvents(selectedTenantID));
      dispatch(
        fetchChartData(
          selectedTenantID,
          buildChartQueries(selectedTenantID, timePeriod, service)
        )
      );
    }
  }, [selectedTenantID, service, timePeriod, dispatch]);

  return (
    <>
      <div className={PageCommon.listviewtablecontrols}>
        <div className={PageCommon.listviewtablecontrolsToolTip}>
          <ToolTip>
            <>Metrics and events for your tenant</>
          </ToolTip>
        </div>
      </div>

      <ConnectedInstanceList />
      <ConnectedChartGrid />
      <ConnectedEventLogList />
    </>
  );
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  service: state.chartDataService,
  timePeriod: state.chartDataTimePeriod,
  location: state.location,
  query: state.query,
  featureFlags: state.featureFlags,
}))(StatusPage);
