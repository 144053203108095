import React from 'react';
import { connect } from 'react-redux';

import { AppDispatch, RootState } from '../store';

import { SelectedTenant } from '../models/Tenant';

import EdgeTable from '../controls/EdgeTable';

const EdgesPage = ({
  selectedTenant,
  location,
  query,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  location: URL;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  return <EdgeTable selectedTenant={selectedTenant} createButton />;
};

const ConnectedEdgesPage = connect((state: RootState) => {
  return {
    edge: state.currentEdgeType,
    objectTypes: state.objectTypes,
    objectTypeError: state.fetchObjectTypesError,
    edgeTypes: state.edgeTypes,
    edgeTypeError: state.fetchEdgeTypesError,
    selectedTenant: state.selectedTenant,
    edgeTypeEditMode: state.edgeTypeEditMode,
    edgeTypeDeleteQueue: state.edgeTypeDeleteQueue,
    objectTypeEditMode: state.objectTypeEditMode,
    objectTypeDeleteQueue: state.objectTypeDeleteQueue,
    location: state.location,
    query: state.query,
  };
})(EdgesPage);

export default ConnectedEdgesPage;
