import React from 'react';
import { IconTypes, getSize } from '../iconHelpers';

function IconSettingsGear({
  className,
  size = 'medium',
}: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      className={className}
      fill="currentColor"
    >
      <title>Settings Gear</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.8333 7.77409H19.6259L19.6296 7.78143C19.8333 7.78143 20 7.94811 20 8.15181V11.8555C20 12.0592 19.8333 12.2259 19.6296 12.2259H17.837C17.6667 12.837 17.4259 13.4222 17.1148 13.9741L18.3852 15.2444C18.5296 15.3889 18.5296 15.6222 18.3852 15.7667L15.7667 18.3852C15.6963 18.4518 15.6037 18.4926 15.5037 18.4926C15.4037 18.4926 15.3111 18.4555 15.2408 18.3852L13.9704 17.1148C13.4185 17.4222 12.8334 17.6666 12.2222 17.837V19.6296C12.2222 19.8333 12.0556 20 11.8519 20H8.14813C7.94443 20 7.77776 19.8333 7.77776 19.6296V17.837C7.16663 17.6667 6.58146 17.4259 6.0296 17.1148L4.75923 18.3852C4.68887 18.4518 4.59626 18.4926 4.49627 18.4926C4.39628 18.4926 4.30369 18.4555 4.23332 18.3852L1.61483 15.7667C1.54816 15.6963 1.50742 15.6037 1.50742 15.5037C1.50742 15.4037 1.54446 15.3111 1.61483 15.2408L2.8852 13.9704C2.5778 13.4185 2.33335 12.8334 2.16298 12.2222H0.370373C0.166668 12.2222 0 12.0556 0 11.8519V8.14813C0 7.94443 0.166678 7.77776 0.370373 7.77776H2.16298C2.33333 7.16663 2.57408 6.58146 2.8852 6.0296L1.61483 4.75923C1.54816 4.68887 1.50742 4.59626 1.50742 4.49627C1.50742 4.39628 1.54446 4.30369 1.61483 4.23332L4.23332 1.61483C4.37036 1.47409 4.61481 1.47409 4.75555 1.61483L6.02592 2.8852C6.57779 2.5778 7.16296 2.33335 7.77408 2.16298V0.370373C7.77408 0.166668 7.94076 0 8.14446 0H11.8482C12.0519 0 12.2186 0.166678 12.2186 0.370373V2.16298C12.8297 2.33333 13.4149 2.57408 13.9667 2.8852L15.2371 1.61483C15.3075 1.54816 15.4001 1.50742 15.5001 1.50742C15.6 1.50742 15.6926 1.54446 15.763 1.61483L18.3815 4.23332C18.5259 4.37777 18.5259 4.61111 18.3815 4.75555L17.1111 6.02592C17.4185 6.57779 17.663 7.16296 17.8333 7.77409ZM4.83283 9.99504C4.83283 7.13576 7.15878 4.80982 10.0181 4.80982C12.8773 4.80982 15.2033 7.13576 15.2033 9.99504C15.2033 12.8543 12.8773 15.1803 10.0181 15.1803C7.15878 15.1803 4.83283 12.8543 4.83283 9.99504Z"
        fill="#283354"
      />
      <path
        d="M10 7C8.34571 7 7 8.34571 7 10C7 11.6543 8.34571 13 10 13C11.6543 13 13 11.6543 13 10C13 8.34571 11.6543 7 10 7Z"
        fill="#283354"
      />
    </svg>
  );
}

export default IconSettingsGear;
