import React, { useRef } from 'react';
import clsx from 'clsx';

import Heading from '../Heading';
import Text from '../Text';
import IconButton from '../IconButton';
import { IconClose } from '../../icons';
import styles from './index.module.scss';
import cardStyles from '../../layouts/Card/index.module.scss';

export type DialogProps = React.ComponentProps<'dialog'> & {
  title: string;
  description: string;
  fullPage: boolean;
  isDismissable: boolean;
};

/**
 * Commonly used with the input components, like `<TextInput>` or `<Select>`. Clicking
 * on the label will place focus its input by using the `htmlFor` attr and maching that
 * to the `id` of the input.
 *
 * Note that its disabled and error states should be tied with the state of the input.
 */

const Dialog: React.FC<DialogProps> = ({
  open,
  title = '',
  description = '',
  fullPage = false,
  isDismissable = true,
  children,
  className,
  ...args
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const classes = clsx({
    [cardStyles.card]: true,
    [styles.root]: true,
    [styles.fullPage]: fullPage,
    [className]: className,
  });

  return (
    <dialog className={classes} open={open} {...args} ref={dialogRef}>
      {title && (
        <Heading size={2} headingLevel={1} className={styles.heading}>
          {title}
        </Heading>
      )}
      {description && (
        <Text size={1} className={styles.description}>
          {description}
        </Text>
      )}
      <div className={styles.dialogBody}>{children}</div>
      {isDismissable && (
        <IconButton
          icon={<IconClose size="small" />}
          title="Close dialog"
          className={styles.closeButton}
          onClick={() => {
            dialogRef.current.close();
          }}
        />
      )}
    </dialog>
  );
};

export default Dialog;
