import React from 'react';
import { IconTypes, getSize } from '../iconHelpers';

function IconUserReceived2({
  className,
  size = 'medium',
}: IconTypes): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={getSize(size)}
      height={getSize(size)}
      className={className}
      fill="currentColor"
    >
      <title>User with left arrow</title>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M14 14.252V22H4a8 8 0 0 1 10-7.748zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm8 4h3v2h-3v3.5L15 18l5-4.5V17z" />
    </svg>
  );
}

export default IconUserReceived2;
