import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootState, AppDispatch } from '../store';
import { fetchAccessors } from '../thunks/accessors';

import AccessorList from './AccessorList';
import { FeatureFlags } from '../models/FeatureFlag';

const ConnectedAccessorList = connect((state: RootState) => ({
  selectedCompanyID: state.selectedCompanyID,
  selectedTenant: state.selectedTenant,
  accessors: state.accessors,
  userStoreColumns: state.userStoreColumns,
  isFetching: state.fetchingAccessors,
  fetchError: state.fetchAccessorsError,
  deleteQueue: state.accessorsToDelete,
  editMode: state.accessorListEditMode,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  isSaving: state.updatingAccessors,
  saveSuccess: state.bulkUpdateAccessorsSuccess,
  saveErrors: state.bulkUpdateAccessorsErrors,
  query: state.query,
  featureFlags: state.featureFlags,
  accessorSearchFilter: state.accessorSearchFilter,
}))(AccessorList);

const AccessorsPage = ({
  selectedTenantID,
  location,
  query,
  includeAutogenerated,
  featureFlags,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  location: URL;
  query: URLSearchParams;
  includeAutogenerated: boolean;
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchAccessors(selectedTenantID, query, includeAutogenerated));
    }
  }, [selectedTenantID, query, dispatch, includeAutogenerated]);

  return <ConnectedAccessorList />;
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  location: state.location,
  query: state.query,
  includeAutogenerated: state.accessorListIncludeAutogenerated,
  featureFlags: state.featureFlags,
}))(AccessorsPage);
