import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  CardFooter,
  CardRow,
  EmptyState,
  GlobalStyles,
  HiddenTextInput,
  IconDatabase2,
  InlineNotification,
  InputReadOnly,
  InputReadOnlyHidden,
  Label,
  Select,
  TextInput,
} from '@userclouds/ui-component-lib';

import { RootState, AppDispatch } from '../store';
import {
  toggleEditUserstoreDatabaseMode,
  modifyUserstoreDatabase,
  editUserStoreDatabaseError,
  cancelDatabaseDialog,
} from '../actions/userstore';
import { updateTenantSqlShim, getTenantSqlShims } from '../thunks/userstore';
import { PageTitle } from '../mainlayout/PageWrap';
import { SelectedTenant } from '../models/Tenant';
import ServiceInfo from '../ServiceInfo';
import { getDatabaseError, SqlshimDatabase } from '../models/SqlshimDatabase';
import { redirect } from '../routing';

const DatabaseDetailsPage = ({
  selectedTenant,
  companyID,
  editingDatabase,
  currentDatabase,
  modifiedDatabase,
  fetchingDatabase,
  savingDatabase,
  saveError,
  serviceInfo,
  routeParams,
  dialog = false,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  companyID: string | undefined;
  editingDatabase: boolean;
  currentDatabase: SqlshimDatabase | undefined;
  modifiedDatabase: SqlshimDatabase | undefined;
  fetchingDatabase: boolean;
  savingDatabase: boolean;
  saveError: string;
  serviceInfo: ServiceInfo | undefined;
  routeParams: Record<string, string>;
  dialog?: boolean;
  dispatch: AppDispatch;
}) => {
  const { tenantID } = routeParams;

  useEffect(() => {
    if (selectedTenant && tenantID) {
      if (tenantID !== selectedTenant.id) {
        redirect(
          `/tenants/${tenantID}?tenant_id=${tenantID}&company_id=${
            companyID as string
          }`
        );
      }
    }
  }, [selectedTenant, companyID, tenantID]);

  useEffect(() => {
    if (!currentDatabase && selectedTenant && !dialog) {
      dispatch(getTenantSqlShims(selectedTenant.id));
    }
  }, [currentDatabase, selectedTenant, dialog, dispatch]);

  return (
    <>
      <PageTitle
        title={
          dialog ? '' : editingDatabase ? 'Edit Database' : 'Database Details'
        }
        itemName={
          dialog ? '' : currentDatabase?.host || 'No database configured'
        }
        newdesign
      />
      <Card isDirty={editingDatabase} detailview>
        {selectedTenant && currentDatabase ? (
          <CardRow
            title="Connection Details"
            tooltip="Add the database connection details."
            collapsible={false}
            isClosed={false}
          >
            {currentDatabase.host || editingDatabase ? (
              <form
                onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                  e.preventDefault();
                  const onSave = () => {
                    if (dialog) {
                      const dialogDB = (e.target as HTMLFormElement).closest(
                        'dialog'
                      );
                      if (dialogDB) {
                        dialogDB.close();
                      }
                    }
                  };

                  !dialog &&
                    modifiedDatabase &&
                    dispatch(
                      updateTenantSqlShim(
                        selectedTenant.id,
                        modifiedDatabase,
                        onSave
                      )
                    );
                }}
              >
                {saveError && (
                  <InlineNotification theme="alert">
                    {saveError}
                  </InlineNotification>
                )}

                <Label>
                  Database Type
                  <br />
                  {editingDatabase && modifiedDatabase ? (
                    <Select
                      full
                      defaultValue="postgres"
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const dbType = (e.target as HTMLSelectElement).value;
                        dispatch(modifyUserstoreDatabase({ type: dbType }));
                      }}
                    >
                      <option key="postgres" value="postgres">
                        PostgreSQL
                      </option>
                      <option key="mysql" value="mysql">
                        MySQL
                      </option>
                    </Select>
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.type || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>
                <Label>
                  Proxy Hostname
                  <br />
                  {serviceInfo && serviceInfo.uc_admin ? (
                    editingDatabase && modifiedDatabase ? (
                      <TextInput
                        name="proxy_host"
                        value={modifiedDatabase.proxy_host}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;
                          dispatch(
                            modifyUserstoreDatabase({ proxy_host: val })
                          );
                        }}
                      />
                    ) : (
                      <InputReadOnly>
                        {currentDatabase.proxy_host || 'Not configured'}
                      </InputReadOnly>
                    )
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.proxy_host || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>
                <Label>
                  Proxy Port
                  <br />
                  {serviceInfo && serviceInfo.uc_admin ? (
                    editingDatabase && modifiedDatabase ? (
                      <TextInput
                        name="proxy_port"
                        value={modifiedDatabase.proxy_port}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = Number(e.target.value);
                          dispatch(
                            modifyUserstoreDatabase({ proxy_port: val })
                          );
                        }}
                      />
                    ) : (
                      <InputReadOnly>
                        {currentDatabase.proxy_port || 'Not configured'}
                      </InputReadOnly>
                    )
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.proxy_port || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>

                <Label className={GlobalStyles['mt-6']}>
                  Database Name
                  <br />
                  {editingDatabase && modifiedDatabase ? (
                    <TextInput
                      name="name"
                      value={modifiedDatabase.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value;
                        dispatch(modifyUserstoreDatabase({ name: val }));
                      }}
                    />
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.name || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>
                <Label className={GlobalStyles['mt-6']}>
                  Database Host
                  <br />
                  {editingDatabase && modifiedDatabase ? (
                    <TextInput
                      name="host"
                      value={modifiedDatabase.host}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value;
                        dispatch(modifyUserstoreDatabase({ host: val }));
                      }}
                    />
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.host || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>
                <Label>
                  Database Port
                  <br />
                  {editingDatabase && modifiedDatabase ? (
                    <TextInput
                      name="port"
                      value={modifiedDatabase.port}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = Number(e.target.value);
                        dispatch(modifyUserstoreDatabase({ port: val }));
                      }}
                    />
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.port || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>
                <Label>
                  Username
                  <br />
                  {editingDatabase && modifiedDatabase ? (
                    <TextInput
                      name="username"
                      value={modifiedDatabase.username}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value;
                        dispatch(modifyUserstoreDatabase({ username: val }));
                      }}
                    />
                  ) : (
                    <InputReadOnly>
                      {currentDatabase.username || 'Not configured'}
                    </InputReadOnly>
                  )}
                </Label>
                <Label>
                  Password
                  <br />
                  {editingDatabase && modifiedDatabase ? (
                    <HiddenTextInput
                      name="password"
                      value={modifiedDatabase.password}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = e.target.value;
                        dispatch(modifyUserstoreDatabase({ password: val }));
                      }}
                      disabled={!editingDatabase}
                    />
                  ) : (
                    <InputReadOnlyHidden
                      name="password"
                      value=""
                      canBeShown={false}
                    />
                  )}
                </Label>

                <CardFooter>
                  <ButtonGroup className={GlobalStyles['mt-6']}>
                    {editingDatabase &&
                      (dialog ? (
                        <>
                          <Button
                            onClick={(
                              e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              e.preventDefault();
                              const error = getDatabaseError(modifiedDatabase);
                              if (error) {
                                dispatch(editUserStoreDatabaseError(error));
                              } else {
                                const editDBDialog = (
                                  e.target as HTMLButtonElement
                                ).closest('dialog');
                                if (editDBDialog) {
                                  editDBDialog.close();
                                } // changes in reducer so just close. save on tenant page
                              }
                            }}
                            theme="primary"
                            isLoading={savingDatabase || fetchingDatabase}
                          >
                            OK
                          </Button>

                          <Button
                            theme="outline"
                            onClick={(
                              e: React.MouseEvent<HTMLButtonElement>
                            ) => {
                              e.preventDefault();
                              dispatch(
                                cancelDatabaseDialog(currentDatabase.id)
                              );
                              const editDBDialog = (
                                e.target as HTMLButtonElement
                              ).closest('dialog');
                              if (editDBDialog) {
                                editDBDialog.close();
                              }
                            }}
                            isLoading={savingDatabase || fetchingDatabase}
                          >
                            Cancel
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            type="submit"
                            theme="primary"
                            isLoading={savingDatabase || fetchingDatabase}
                          >
                            Save
                          </Button>

                          <Button
                            theme="outline"
                            onClick={() => {
                              dispatch(toggleEditUserstoreDatabaseMode(false));
                            }}
                            isLoading={savingDatabase || fetchingDatabase}
                          >
                            Cancel
                          </Button>
                        </>
                      ))}
                    {selectedTenant?.is_admin && !editingDatabase && (
                      <Button
                        theme="primary"
                        onClick={() => {
                          dispatch(toggleEditUserstoreDatabaseMode(true));
                        }}
                      >
                        Edit
                      </Button>
                    )}
                  </ButtonGroup>
                </CardFooter>
              </form>
            ) : (
              <EmptyState
                title="No database connections"
                image={<IconDatabase2 size="large" />}
              >
                {selectedTenant.is_admin && (
                  <Button
                    theme="primary"
                    onClick={() => {
                      dispatch(toggleEditUserstoreDatabaseMode(true));
                    }}
                  >
                    Configure one now
                  </Button>
                )}
              </EmptyState>
            )}
          </CardRow>
        ) : (
          'Loading database connections...'
        )}
      </Card>
    </>
  );
};

export default connect((state: RootState) => {
  return {
    selectedTenant: state.selectedTenant,
    companyID: state.selectedCompanyID,
    editingDatabase: state.editingSqlshimDatabase,
    currentDatabase: state.currentSqlshimDatabase,
    modifiedDatabase: state.modifiedSqlshimDatabase,
    fetchingDatabase: state.fetchingSqlshimDatabase,
    savingDatabase: state.savingSqlshimDatabase,
    saveError: state.saveSqlshimDatabaseError,
    serviceInfo: state.serviceInfo,
    routeParams: state.routeParams,
  };
})(DatabaseDetailsPage);
