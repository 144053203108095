import React, { useState } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';

import {
  Heading,
  Text,
  ToastSolid,
  PageWrapStyles as styles,
  PageTitleStyles,
} from '@userclouds/ui-component-lib';

import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import { RootState, AppDispatch } from '../store';
import Notification from '../models/Notification';
import { removePostedNotification } from '../actions/notifications';
import { FeatureFlags } from '../models/FeatureFlag';
import Styles from './PageWrap.module.css';

interface PageTitleProps {
  title: string;
  description?: string | JSX.Element;
  itemName?: string;
  newdesign?: boolean;
  id?: string;
}

const ConnectedPageTitle: React.FC<PageTitleProps> = ({
  title,
  description,
  itemName,
  newdesign = false,
  id,
}) => {
  const classes = clsx({
    [PageTitleStyles.root]: !newdesign,
    [Styles.title]: newdesign,
  });

  return (
    <div className={classes} id={id}>
      {newdesign ? (
        <Heading size={2} headingLevel={1}>
          {title}
          {itemName ? (
            <>
              {': '}
              <b>{itemName}</b>
            </>
          ) : (
            ''
          )}
        </Heading>
      ) : (
        <Heading size={1} headingLevel={1} className={PageTitleStyles.heading}>
          {title}
        </Heading>
      )}
      {description && (
        <Text className={PageTitleStyles.description}>{description}</Text>
      )}
    </div>
  );
};

export const PageTitle = connect((state: RootState) => ({
  featureFlags: state.featureFlags,
}))(ConnectedPageTitle);

interface PageWrapProps {
  children: React.ReactNode;
  notifications: Notification[];
  featureFlags: FeatureFlags | undefined;
  dispatch: AppDispatch;
}

export const PageWrap = ({
  children,
  notifications,
  featureFlags,
  dispatch,
}: PageWrapProps) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const handleSideBar = (value: boolean) => {
    setSideBarOpen(value);
  };

  const classes = clsx({
    [Styles.root]: true,
  });

  const contentClasses = clsx({
    [styles.content]: true,
    [Styles.content]: true,
  });

  const contentInnerClasses = clsx({
    [Styles.contentInner]: true,
  });
  return (
    <div className={classes}>
      <Header handleSideBar={handleSideBar} />
      <div className={styles.inner}>
        <SideBar isOpen={sideBarOpen} handleSideBar={handleSideBar} />
        <main className={Styles.main} id="pageContent">
          {notifications.length > 0 && (
            <ol id="notificationCenter" className={styles.toastNotifications}>
              {notifications.map((notification: Notification) => (
                <li className={styles.animate} key={notification.message}>
                  <ToastSolid
                    theme={notification.type as string}
                    isDismissable
                    onDismissClick={() => {
                      dispatch(removePostedNotification(notification.id));
                    }}
                  >
                    {notification.message}
                  </ToastSolid>
                </li>
              ))}
            </ol>
          )}
          <div className={contentClasses}>
            <div className={contentInnerClasses}>{children}</div>
            <div className={Styles.footer}>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default connect((state: RootState) => ({
  notifications: state.notifications,
  featureFlags: state.featureFlags,
}))(PageWrap);
