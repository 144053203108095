import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Dialog,
  IconButton,
  IconDeleteBin,
  InlineNotification,
  Label,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TableRowHead,
  Text,
  TextInput,
  ToolTip,
} from '@userclouds/ui-component-lib';

import { RootState, AppDispatch } from '../store';
import { PolicySecret, POLICY_SECRET_PREFIX } from '../models/PolicySecret';
import { SelectedTenant } from '../models/Tenant';
import PaginatedResult from '../models/PaginatedResult';
import {
  bulkDeletePolicySecrets,
  deletePolicySecret,
  fetchPolicySecrets,
  saveNewPolicySecret,
} from '../thunks/tokenizer';
import {
  initializeNewPolicySecret,
  togglePolicySecretForDelete,
  togglePolicySecretsDeleteAll,
  modifyPolicySecret,
} from '../actions/tokenizer';
import Pagination from '../controls/Pagination';
import PageCommon from './PageCommon.module.css';

const NewSecretDialog = ({
  dialogID,
  selectedTenantID,
  modifiedSecret,
  saveError,
  dispatch,
}: {
  dialogID: string;
  selectedTenantID: string | undefined;
  modifiedSecret: PolicySecret | undefined;
  saveError: string;
  dispatch: AppDispatch;
}) => {
  return (
    <Dialog
      id={dialogID}
      title="Create New Secret"
      description="Add a new secret. Secrets are stored securely and can be used in your policies."
    >
      {saveError && (
        <InlineNotification theme="alert">{saveError}</InlineNotification>
      )}
      {modifiedSecret && selectedTenantID && (
        <>
          <Label>
            Name
            <br />
            <TextInput
              name="secret_name"
              value={modifiedSecret.name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(modifyPolicySecret({ name: e.target.value }));
              }}
            />
          </Label>
          <Label>
            Value
            <br />
            <TextInput
              name="secret_value"
              value={modifiedSecret.value}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(modifyPolicySecret({ value: e.target.value }));
              }}
            />
          </Label>
          <footer>
            <ButtonGroup>
              <Button
                onClick={() => {
                  dispatch(
                    saveNewPolicySecret(
                      selectedTenantID,
                      modifiedSecret,
                      () => {
                        const dialog: HTMLDialogElement | null =
                          document.getElementById(
                            dialogID
                          ) as HTMLDialogElement;
                        dialog.close();
                      }
                    )
                  );
                }}
                theme="primary"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  const dialog: HTMLDialogElement | null =
                    document.getElementById(dialogID) as HTMLDialogElement;
                  dialog.close();
                }}
                theme="outline"
              >
                Cancel
              </Button>
            </ButtonGroup>
          </footer>
        </>
      )}
    </Dialog>
  );
};

// Exporting the dialog separately lets us place it where we want in the DOM
// and thus avoid nesting forms
export const ConnectedNewSecretDialog = connect((state: RootState) => ({
  modifiedSecret: state.modifiedPolicySecret,
  selectedTenantID: state.selectedTenantID,
  saveError: state.savePolicySecretError,
}))(NewSecretDialog);

const SecretRow = ({
  selectedTenantID,
  policySecret,
  policySecretDeleteQueue,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  policySecret: PolicySecret;
  policySecretDeleteQueue: string[];
  dispatch: AppDispatch;
}) => {
  const queuedForDelete = policySecretDeleteQueue.includes(policySecret.id);

  return (
    <TableRow
      key={`policySecret_row_${policySecret.id}`}
      className={
        (queuedForDelete ? PageCommon.queuedfordelete : '') +
        ' ' +
        PageCommon.listviewtablerow
      }
    >
      <TableCell>
        <Checkbox
          id={'delete' + policySecret.id}
          name={'delete' + policySecret.id}
          checked={queuedForDelete}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(togglePolicySecretForDelete(policySecret));
          }}
        />
      </TableCell>
      <TableCell>
        <Text>{policySecret.name}</Text>
      </TableCell>
      <TableCell>
        <Text>{new Date(policySecret.created * 1000).toLocaleString()}</Text>
      </TableCell>
      <TableCell className={PageCommon.listviewtabledeletecell} align="right">
        <IconButton
          icon={<IconDeleteBin />}
          onClick={() => {
            const prompt = `This action will delete secret ${policySecret.name}. Proceed?`;
            const ok = window.confirm(prompt);
            if (!ok) {
              return;
            }
            if (selectedTenantID) {
              dispatch(deletePolicySecret(selectedTenantID, policySecret.id));
            }
          }}
          title="Delete Secret"
        />
      </TableCell>
    </TableRow>
  );
};
const ConnectedSecretRow = connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  policySecretDeleteQueue: state.policySecretDeleteQueue,
}))(SecretRow);

const SecretsTable = ({
  selectedTenant,
  policySecrets,
  isFetching,
  error,
  query,
  deleteQueue,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  policySecrets: PaginatedResult<PolicySecret> | undefined;
  isFetching: boolean;
  error: string;
  query: URLSearchParams;
  deleteQueue: string[];
  dispatch: AppDispatch;
}) => {
  return (
    <>
      {policySecrets ? (
        <Table spacing="packed" id="policySecretsTable">
          <TableHead floating={true}>
            <TableRow>
              <TableRowHead>
                <Checkbox
                  checked={
                    policySecrets.data.length > 0 &&
                    Object.keys(deleteQueue).length ===
                      policySecrets.data.length
                  }
                  disabled={policySecrets.data.length === 0}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    dispatch(togglePolicySecretsDeleteAll(e.target.checked));
                  }}
                />
              </TableRowHead>
              <TableRowHead key="secret_name_header">Name</TableRowHead>
              <TableRowHead key="secret_created_header">Created</TableRowHead>
              <TableRowHead key="delete_header"></TableRowHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {policySecrets.data.length ? (
              <>
                {policySecrets.data.map((policySecret: PolicySecret) => (
                  <ConnectedSecretRow
                    policySecret={policySecret}
                    key={policySecret.id}
                  />
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No secrets.</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      ) : isFetching ? (
        <Text>Fetching secrets...</Text>
      ) : (
        <InlineNotification theme="alert">
          {error || 'Something went wrong'}
        </InlineNotification>
      )}
    </>
  );
};
const ConnectedSecretsTable = connect((state: RootState) => ({
  selectedTenant: state.selectedTenant,
  selectedTenantID: state.selectedTenantID,
  isFetching: state.fetchingDataType,
  error: state.fetchingDataTypeError,
  query: state.query,
  deleteQueue: state.policySecretDeleteQueue,
}))(SecretsTable);

const Secrets = ({
  selectedTenant,
  policySecrets,
  fetching,
  saveSuccess,
  saveErrors,
  query,
  deleteQueue,
  dispatch,
}: {
  selectedTenant: SelectedTenant | undefined;
  policySecrets: PaginatedResult<PolicySecret> | undefined;
  fetching: boolean;
  saveSuccess: string;
  saveErrors: string[];
  query: URLSearchParams;
  deleteQueue: string[];
  dispatch: AppDispatch;
}) => {
  const dialogID = 'newSecretDialog';
  return (
    <>
      <ConnectedNewSecretDialog dialogID={dialogID} />
      <div className={PageCommon.listviewtablecontrols}>
        <div className={PageCommon.listviewtablecontrolsToolTip}>
          <ToolTip>Create secrets to be accessed from your policies.</ToolTip>
        </div>

        {selectedTenant?.is_admin && (
          <Button
            theme="primary"
            size="small"
            className={PageCommon.listviewtablecontrolsButton}
            onClick={() => {
              dispatch(initializeNewPolicySecret());
              const dialog: HTMLDialogElement | null = document.getElementById(
                dialogID
              ) as HTMLDialogElement;
              dialog.showModal();
            }}
          >
            Create Secret
          </Button>
        )}
      </div>

      <Card
        id="userstorePolicySecrets"
        lockedMessage={
          !selectedTenant?.is_admin ? 'You do not have edit access' : ''
        }
        listview={true}
      >
        {!!saveErrors.length && (
          <InlineNotification theme="alert">
            {saveErrors.length === 1
              ? saveErrors[0]
              : `${saveErrors.length} errors occurred while saving your edits`}
          </InlineNotification>
        )}
        {saveSuccess && (
          <InlineNotification theme="success">{saveSuccess}</InlineNotification>
        )}
        {policySecrets ? (
          <>
            <div className={PageCommon.listviewpaginationcontrols}>
              <IconButton
                icon={<IconDeleteBin />}
                onClick={() => {
                  let prompt = `This action will delete ${deleteQueue.length} secrets. Proceed?`;

                  const ok = window.confirm(prompt);
                  if (!ok) {
                    return;
                  }
                  if (selectedTenant) {
                    dispatch(
                      bulkDeletePolicySecrets(selectedTenant.id, deleteQueue)
                    );
                  }
                }}
                size="small"
                disabled={deleteQueue.length < 1}
                className={PageCommon.listviewpaginationcontrolsdelete}
                id="deleteButton"
              />
              <Pagination
                prev={policySecrets?.prev}
                next={policySecrets?.next}
                isLoading={fetching}
                prefix={POLICY_SECRET_PREFIX}
              />
            </div>
            <ConnectedSecretsTable policySecrets={policySecrets} />
          </>
        ) : fetching ? (
          <Text>Fetching tenant User Store config...</Text>
        ) : (
          <InlineNotification theme="alert">
            {'Error fetching secrets'}
          </InlineNotification>
        )}
      </Card>
    </>
  );
};
const ConnectedSecrets = connect((state: RootState) => ({
  selectedTenant: state.selectedTenant,
  policySecrets: state.policySecrets,
  fetching: state.fetchingPolicySecrets,
  saveSuccess: state.saveUserStoreConfigSuccess,
  saveErrors: state.saveUserStoreConfigErrors,
  query: state.query,
  deleteQueue: state.policySecretDeleteQueue,
}))(Secrets);

const SecretsPage = ({
  selectedTenantID,
  query,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchPolicySecrets(selectedTenantID, query));
    }
  }, [selectedTenantID, query, dispatch]);

  return <ConnectedSecrets />;
};

export default connect((state: RootState) => ({
  selectedTenantID: state.selectedTenantID,
  query: state.query,
}))(SecretsPage);
