import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  CardRow,
  CardFooter,
  Dialog,
  GlobalStyles,
  IconButton,
  IconDeleteBin,
  IconEdit,
  InlineNotification,
  InputReadOnly,
  Label,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableRowHead,
  Text,
  TextInput,
  ToolTip,
  TableCell,
} from '@userclouds/ui-component-lib';

import { APIError } from '@userclouds/sharedui';
import { RootState, AppDispatch } from '../store';
import { redirect } from '../routing';
import ServiceInfo from '../ServiceInfo';

import Tenant, { SelectedTenant } from '../models/Tenant';
import TenantPlexConfig, {
  UpdatePlexConfigReason,
} from '../models/TenantPlexConfig';
import TenantURL from '../models/TenantURL';
import { SqlshimDatabase } from '../models/SqlshimDatabase';
import { FeatureFlags } from '../models/FeatureFlag';
import PaginatedResult from '../models/PaginatedResult';

import {
  saveTenant,
  handleDeleteTenant,
  getTenantURLs,
  getBatchModifyTenantURLPromises,
} from '../thunks/tenants';
import {
  getBatchUpdateDatabasePromises,
  getTenantSqlShims,
} from '../thunks/userstore';
import { fetchPlexConfig } from '../thunks/authn';
import {
  addTenantURL,
  modifyTenantName,
  toggleEditTenantMode,
  modifyTenantURL,
  setCurrentURL,
  setEditingIssuer,
  deleteTenantURL,
  updateTenantURL,
  createTenantURL,
  updateTenantError,
  setFetchingTenant,
} from '../actions/tenants';
import {
  addDatabase,
  deleteDatabase,
  setCurrentDatabase,
} from '../actions/userstore';
import {
  addExternalOIDCIssuer,
  deleteExternalOIDCIssuer,
  editExternalOIDCIssuer,
} from '../actions/authn';
import { validateTenantURL } from '../API/tenants';
import { saveTenantPlexConfig } from '../API/authn';
import { updateTenantDatabaseProxyPorts } from '../API/sqlshimdatabase';

import Link from '../controls/Link';
import { PageTitle } from '../mainlayout/PageWrap';
import PageCommon from './PageCommon.module.css';

import DatabaseDetailsPage from './DatabaseDetailsPage';

const DatabaseDetailsDialog = ({
  tenantDatabaseDialogIsOpen,
}: {
  tenantDatabaseDialogIsOpen: boolean;
  tenantID: string;
  dispatch: AppDispatch;
}) => {
  return (
    <Dialog
      id="tenantDatabaseDialog"
      open={tenantDatabaseDialogIsOpen}
      title="Edit Database"
      description="Configure the details of your database"
      isDismissable={false}
    >
      <DatabaseDetailsPage dialog />
    </Dialog>
  );
};

const ConnectedDatabaseDetailsDialog = connect((state: RootState) => ({
  tenantDatabaseDialogIsOpen: state.tenantDatabaseDialogIsOpen,
}))(DatabaseDetailsDialog);

const urlStatus = (tenantURL: TenantURL, tenant: Tenant) => {
  let status = 'DNS ownership verified. ';
  // TODO: better check here?
  // TODO: red text if expired
  if (tenantURL.certificate_valid_until !== '0001-01-01T00:00:00Z') {
    status +=
      'Certificate valid until ' +
      new Date(tenantURL.certificate_valid_until).toLocaleDateString() +
      '. ';
  } else {
    status += 'Certificate is not yet issued. ';
  }

  if (tenantURL.active) {
    status += 'CNAME verified. ';
  } else if (tenant && tenant.tenant_url) {
    status +=
      'Domain is not currently CNAMEd to ' +
      tenant.tenant_url.replace(/https?:\/\//, '') +
      '. ';
  }

  return status;
};

const TenantURLDialog = ({
  tenant,
  url,
  tenantURLs,
  editing,
  errorMessage,
  isDirty,
  creatingNew,
  featureFlags,
  tenantURLDialogIsOpen,
  isSaving,
  onSave,
  dispatch,
}: {
  tenant: Tenant | undefined;
  url: TenantURL | undefined;
  tenantURLs: TenantURL[];
  editing: boolean;
  isDirty: boolean;
  errorMessage: string;
  creatingNew: boolean;
  featureFlags: FeatureFlags | undefined;
  tenantURLDialogIsOpen: boolean;
  isSaving: boolean;
  onSave?: Function;
  dispatch: AppDispatch;
}) => {
  return (
    <Dialog
      id="tenantURLDialog"
      title="Tenant URL"
      open={tenantURLDialogIsOpen}
      isDismissable={false}
    >
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          const dialog = (e.target as HTMLButtonElement).closest('dialog');
          if (dialog) {
            dialog.close();
          }
          url &&
            (creatingNew
              ? dispatch(createTenantURL(url))
              : dispatch(updateTenantURL(url)));
        }}
      >
        {!creatingNew && errorMessage && (
          <InlineNotification theme="alert" className={GlobalStyles['mt-3']}>
            {errorMessage}
          </InlineNotification>
        )}
        <Label>
          Enter a valid HTTPS URL
          <br />
          <TextInput
            id={url?.id || 'new_url'}
            name="tenant_url"
            value={url?.tenant_url}
            type="url"
            pattern="https://.*"
            required
            placeholder="Enter Valid HTTPS url (e.g. (eg. auth.yourcompany.com)"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              dispatch(
                modifyTenantURL({
                  id: url ? url.id : '',
                  tenant_url: e.target.value,
                })
              );
            }}
          />
        </Label>

        {!creatingNew && (
          <>
            {url?.validated ? (
              <Text className={GlobalStyles['mt-6']}>
                {urlStatus(url, tenant as Tenant)}
              </Text>
            ) : (
              <>
                <Text className={GlobalStyles['mt-6']}>
                  DNS ownership not validated.{' '}
                  {url?.dns_verifier !== '' &&
                    url?.dns_verifier !== undefined && (
                      <>
                        Please create a DNS TXT record at{' '}
                        <b>
                          _acme-challenge.
                          {url.tenant_url.replace(/https?:\/\//, '')}
                        </b>{' '}
                        with value <b>{url.dns_verifier}</b>
                      </>
                    )}
                </Text>
                <ButtonGroup>
                  <Button
                    theme="outline"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      if (url) {
                        validateTenantURL(url);
                        const dialog = (e.target as HTMLButtonElement).closest(
                          'dialog'
                        );
                        if (dialog) {
                          dialog.close();
                        }
                        dispatch(getTenantURLs(url.tenant_id));
                      }
                    }}
                  >
                    Refresh
                  </Button>
                </ButtonGroup>
              </>
            )}
          </>
        )}

        <ButtonGroup>
          <Button
            disabled={isSaving}
            isLoading={isSaving}
            theme="primary"
            type="submit"
          >
            OK
          </Button>
          <Button
            theme="outline"
            id="cancelURL"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              const dialog = (e.target as HTMLButtonElement).closest('dialog');
              if (dialog) {
                dialog.close();
              }
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </form>
    </Dialog>
  );
};

const ConnectedTenantURLDialog = connect((state: RootState) => ({
  tenant: state.selectedTenant,
  tenantURLs: state.tenantURLs || [],
  url: state.currentTenantURL,
  creatingNew: state.creatingNewTenantURL,
  editing: state.editingTenantURL,
  errorMessage: state.editingTenantURLError,
  isDirty: state.tenantURLIsDirty,
  isSaving: state.savingTenantURLs,
  tenantURLDialogIsOpen: state.tenantURLDialogIsOpen,
  featureFlags: state.featureFlags,
}))(TenantURLDialog);

const TenantDatabases = ({
  editing,
  currentDatabases,
  modifiedDatabases,
  dispatch,
}: {
  editing: boolean;
  currentDatabases: PaginatedResult<SqlshimDatabase> | undefined;
  modifiedDatabases: SqlshimDatabase[];
  dispatch: AppDispatch;
}) => {
  const databases = editing
    ? modifiedDatabases
    : currentDatabases?.data?.length
      ? currentDatabases.data
      : [];
  return (
    <>
      <Table id="databases">
        <TableHead floating key="db_head">
          <TableRow>
            <TableRowHead>Database Name</TableRowHead>
            <TableRowHead>Proxy Host Address</TableRowHead>
            <TableRowHead>Proxy Port</TableRowHead>
            <TableRowHead />
          </TableRow>
        </TableHead>
        <TableBody>
          {databases && databases.length > 0 ? (
            databases.map((database) => {
              return (
                <TableRow key={database.id}>
                  <TableCell>{database.name}</TableCell>
                  <TableCell>{database.proxy_host || '—'}</TableCell>
                  <TableCell>{database.proxy_port || '—'}</TableCell>
                  {editing && (
                    <TableCell align="right">
                      <IconButton
                        icon={<IconEdit />}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          const dialog = (
                            e.target as HTMLButtonElement
                          ).closest('dialog');
                          if (dialog) {
                            dialog.close();
                          }
                          dispatch(setCurrentDatabase(database.id));
                          const tenantURLDialog = document.getElementById(
                            'tenantDatabaseDialog'
                          ) as HTMLDialogElement;
                          tenantURLDialog?.showModal();
                        }}
                        title="Edit Tenant Database"
                      />
                      <IconButton
                        icon={<IconDeleteBin />}
                        onClick={() => {
                          dispatch(deleteDatabase(database.id));
                        }}
                        title="Remove Tenant Database"
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })
          ) : (
            <TableRow key="database_none">
              <TableCell colSpan={3}> None added (not required)</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {editing && (
        <CardFooter>
          <Button
            theme="secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              const dialog = (e.target as HTMLButtonElement).closest('dialog');
              if (dialog) {
                dialog.close();
              }
              const tenantDatabaseDialog = document.getElementById(
                'tenantDatabaseDialog'
              ) as HTMLDialogElement;
              dispatch(addDatabase());

              tenantDatabaseDialog?.showModal();
            }}
          >
            Add Database
          </Button>
        </CardFooter>
      )}
    </>
  );
};

const TenantURLRow = ({
  tenant,
  url,
  editing,
  dispatch,
}: {
  tenant: Tenant | undefined;
  url: TenantURL;
  editing: boolean;
  dispatch: AppDispatch;
}) => {
  return (
    <TableRow key={url.id} className={PageCommon.listviewtablerow}>
      <TableCell>{url.tenant_url}</TableCell>
      <TableCell>{url.validated ? 'verified' : 'not verified'}</TableCell>
      <TableCell align="right" className={PageCommon.listviewtabledeletecell}>
        {editing && (
          <IconButton
            icon={<IconEdit />}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              const dialog = (e.target as HTMLButtonElement).closest('dialog');
              if (dialog) {
                dialog.close();
              }
              dispatch(setCurrentURL(url));
              const tenantURLDialog = document.getElementById(
                'tenantURLDialog'
              ) as HTMLDialogElement;
              tenantURLDialog?.showModal();
            }}
            title="Edit Tenant URL"
          />
        )}
        {editing && (
          <IconButton
            icon={<IconDeleteBin />}
            onClick={() => {
              dispatch(deleteTenantURL(url.id));
            }}
            title="Remove tenant URL"
          />
        )}
      </TableCell>
    </TableRow>
  );
};

const ConnectedTenantURLRow = connect((state: RootState) => ({
  tenant: state.selectedTenant,
  tenantURLs: state.tenantURLs || [],
  editing: state.editingTenant,
  errorMessage: state.editingTenantURLError,
  creatingNew: state.creatingNewTenantURL,
  isSaving: state.savingTenantURLs,
}))(TenantURLRow);

const TenantURLs = ({
  tenant,
  tenantURLs,
  modifiedURLs,
  editing,
  dispatch,
}: {
  tenant: Tenant | undefined;
  tenantURLs: TenantURL[];
  modifiedURLs: TenantURL[];
  editing: boolean;
  dispatch: AppDispatch;
}) => {
  return (
    <>
      <Table id="tenant_url">
        <TableHead floating key="tenant_url_head">
          <TableRow>
            <TableRowHead>Tenant URL</TableRowHead>
            <TableRowHead>Verified</TableRowHead>
            <TableRowHead key="purpose_delete" />
          </TableRow>
        </TableHead>
        <TableBody>
          {editing ? (
            modifiedURLs?.length > 0 ? (
              modifiedURLs?.map((tu) => (
                <ConnectedTenantURLRow url={tu} key={tu.id} />
              ))
            ) : (
              <TableRow key="tenant_url">
                <TableCell colSpan={3}> None added (not required)</TableCell>
              </TableRow>
            )
          ) : tenantURLs?.length > 0 ? (
            tenantURLs?.map((tu) => (
              <ConnectedTenantURLRow url={tu} key={tu.id} />
            ))
          ) : (
            <TableRow key="tenant_url">
              <TableCell colSpan={3}> None added (not required)</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {editing && (
        <CardFooter>
          <Button
            theme="secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              const dialog = (e.target as HTMLButtonElement).closest('dialog');
              if (dialog) {
                dialog.close();
              }
              const tenantURLDialog = document.getElementById(
                'tenantURLDialog'
              ) as HTMLDialogElement;
              dispatch(addTenantURL());
              tenantURLDialog?.showModal();
            }}
          >
            Add Custom URL
          </Button>
        </CardFooter>
      )}
    </>
  );
};

const ConnectedTenantURLs = connect((state: RootState) => ({
  tenant: state.selectedTenant,
  tenantURLs: state.tenantURLs ? state.tenantURLs : [],
  modifiedURLs: state.modifiedTenantUrls,
  editing: state.editingTenant,
  errorMessage: state.editingTenantURLError,
  creatingNew: state.creatingNewTenantURL,
  isSaving: state.savingTenantURLs,
  featureFlags: state.featureFlags,
}))(TenantURLs);

const IssuerDetailsDialog = ({
  tenantIssuerDialogIsOpen,
  modifiedConfig,
  plexConfig,
  issuerIndex,
  isNew,
  dispatch,
}: {
  tenantIssuerDialogIsOpen: boolean;
  modifiedConfig: TenantPlexConfig | undefined;
  plexConfig: TenantPlexConfig | undefined;
  issuerIndex: number;
  isNew: boolean;
  dispatch: AppDispatch;
}) => {
  return (
    <Dialog
      id="tenantIssuerDialog"
      title="Issuer"
      open={tenantIssuerDialogIsOpen}
      isDismissable={false}
    >
      <form
        id="issuerDialogForm"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          const dialog = (e.target as HTMLButtonElement).closest('dialog');
          if (dialog) {
            // changes are in reducer so we don't need to save unless confirmed
            dialog.close();
          }
        }}
      >
        <Label>
          Enter a trusted JWT Issuer URL
          <br />
          {modifiedConfig && issuerIndex >= 0 && (
            <TextInput
              id={issuerIndex}
              name="tenant_url"
              value={
                issuerIndex >= 0 &&
                modifiedConfig?.tenant_config.external_oidc_issuers &&
                modifiedConfig?.tenant_config.external_oidc_issuers.length > 0
                  ? modifiedConfig.tenant_config.external_oidc_issuers[
                      issuerIndex
                    ]
                  : ''
              }
              type="url"
              required
              pattern="https://.*"
              placeholder="Enter Valid HTTPS url (e.g. (eg. auth.yourcompany.com)"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(editExternalOIDCIssuer(e.target.value));
              }}
            />
          )}
        </Label>

        <ButtonGroup>
          <Button theme="primary" type="submit">
            OK
          </Button>
          <Button
            theme="outline"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault();
              const dialog = (e.target as HTMLFormElement).closest('dialog');
              if (dialog) {
                dialog.close();
              }
              if (isNew) {
                dispatch(deleteExternalOIDCIssuer(issuerIndex));
              } else if (
                plexConfig?.tenant_config.external_oidc_issuers &&
                plexConfig?.tenant_config.external_oidc_issuers.length > 0
              )
                dispatch(
                  editExternalOIDCIssuer(
                    plexConfig?.tenant_config.external_oidc_issuers[issuerIndex]
                  )
                );
            }}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </form>
    </Dialog>
  );
};

const ConnectedIssuerDetailsDialog = connect((state: RootState) => ({
  tenantIssuerDialogIsOpen: state.tenantIssuerDialogIsOpen,
  plexConfig: state.tenantPlexConfig,
  modifiedConfig: state.modifiedPlexConfig,
  issuerIndex: state.editingIssuerIndex,
  isNew: state.creatingIssuer,
}))(IssuerDetailsDialog);

const Issuers = ({
  tenant,
  editing,
  plexConfig,
  modifiedConfig,
  fetchError,
  dispatch,
}: {
  tenant: Tenant | undefined;
  editing: boolean;
  plexConfig: TenantPlexConfig | undefined;
  modifiedConfig: TenantPlexConfig | undefined;
  fetchError: string;
  dispatch: AppDispatch;
}) => {
  const config = editing ? modifiedConfig : plexConfig;
  return (
    <>
      <Table id="trusted_issuers">
        <TableHead floating key="issuer_url_head">
          <TableRow>
            <TableRowHead>URL</TableRowHead>
            <TableRowHead key="Issuer_edit" />
            <TableRowHead key="Issuer_delete" />
          </TableRow>
        </TableHead>
        <TableBody>
          {config?.tenant_config.external_oidc_issuers &&
          config?.tenant_config.external_oidc_issuers?.length > 0 ? (
            config?.tenant_config.external_oidc_issuers.map(
              (issuer: string, index) => (
                <TableRow className={PageCommon.listviewtablerow} key={issuer}>
                  <TableCell>{issuer}</TableCell>
                  <TableCell
                    align="right"
                    className={PageCommon.listviewtabledeletecell}
                  >
                    {editing && (
                      <IconButton
                        icon={<IconEdit />}
                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                          const dialog = (
                            e.target as HTMLButtonElement
                          ).closest('dialog');
                          if (dialog) {
                            dialog.close();
                          }

                          dispatch(setEditingIssuer(index));

                          const tenantIssuerDialog = document.getElementById(
                            'tenantIssuerDialog'
                          ) as HTMLDialogElement;
                          tenantIssuerDialog?.showModal();
                        }}
                        title="Edit Issuer"
                      />
                    )}
                    {editing && (
                      <IconButton
                        icon={<IconDeleteBin />}
                        onClick={() => {
                          dispatch(deleteExternalOIDCIssuer(index));
                        }}
                        title="Remove issuer"
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            )
          ) : (
            <TableRow key="issuer_url">
              <TableCell colSpan={2}> None added (not required)</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {editing && (
        <CardFooter>
          <Button
            theme="secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              const dialog = (e.target as HTMLButtonElement).closest('dialog');
              if (dialog) {
                dialog.close();
              }
              const tenantDatabaseDialog = document.getElementById(
                'tenantIssuerDialog'
              ) as HTMLDialogElement;
              dispatch(addExternalOIDCIssuer());
              tenantDatabaseDialog?.showModal();
            }}
          >
            Add Issuer
          </Button>
        </CardFooter>
      )}
    </>
  );
};

const ConnectedIssuers = connect((state: RootState) => ({
  tenant: state.selectedTenant,
  editing: state.editingTenant,
  plexConfig: state.tenantPlexConfig,
  modifiedConfig: state.modifiedPlexConfig,
  isDirty: state.plexConfigIsDirty,
  isFetching: state.fetchingPlexConfig,
  fetchError: state.fetchPlexConfigError,
}))(Issuers);

export const loadTenantPage = async (
  tenantID: string,
  dispatch: AppDispatch
) => {
  const promises = [
    dispatch(getTenantURLs(tenantID)),
    dispatch(getTenantSqlShims(tenantID)),
    dispatch(fetchPlexConfig(tenantID)),
  ];

  dispatch(setFetchingTenant(true));
  await Promise.all(promises).then(() => {
    dispatch(toggleEditTenantMode(false));
    dispatch(setFetchingTenant(false));
  });
};

export const saveAllChanges =
  (
    modifiedTenant: Tenant | undefined,
    selectedTenant: SelectedTenant,
    plexConfigIsDirty: boolean,
    modifiedConfig: any,
    currentDatabases: SqlshimDatabase[],
    modifiedDatabases: SqlshimDatabase[],
    tenantURLsToCreate: TenantURL[],
    tenantURLsToUpdate: TenantURL[],
    tenantURLsToDelete: string[],
    tenantID: string
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const promises: Promise<any>[] = [];

      if (
        selectedTenant &&
        modifiedTenant &&
        modifiedTenant?.name !== selectedTenant.name
      ) {
        promises.push(dispatch(saveTenant(modifiedTenant, selectedTenant)));
      }

      if (plexConfigIsDirty && modifiedConfig) {
        promises.push(saveTenantPlexConfig(tenantID, modifiedConfig));
      }

      if (
        selectedTenant &&
        JSON.stringify(modifiedDatabases) !== JSON.stringify(currentDatabases)
      ) {
        promises.push(
          Promise.all(
            getBatchUpdateDatabasePromises(
              selectedTenant.id,
              currentDatabases || [],
              modifiedDatabases || []
            )
          ).then(() => {
            return updateTenantDatabaseProxyPorts(selectedTenant.id);
          })
        );
      }

      if (
        selectedTenant &&
        (tenantURLsToCreate.length > 0 ||
          tenantURLsToUpdate.length > 0 ||
          tenantURLsToDelete.length > 0)
      ) {
        promises.push(
          ...getBatchModifyTenantURLPromises(
            selectedTenant.id,
            tenantURLsToCreate,
            tenantURLsToUpdate,
            tenantURLsToDelete
          )
        );
      }

      await Promise.all(promises).then(() => {
        loadTenantPage(selectedTenant.id, dispatch);
      });
    } catch (error) {
      dispatch(updateTenantError(error as APIError));
    }
  };

const TenantDetailsPage = ({
  serviceInfo,
  companyID,
  selectedTenant,
  modifiedTenant,
  fetchingTenants,
  fetchingSelectedTenant,
  editingTenant,
  savingTenant,
  saveSuccess,
  saveError,
  deletingTenant,
  deleteError,
  tenantURLs,
  tenantURLsIsDirty,
  fetchingTenantURLs,
  fetchingTenantURLsError,
  savingTenantURLs,
  tenantURLsToUpdate,
  tenantURLsToCreate,
  tenantURLsToDelete,
  tenantURLDialogIsOpen,
  plexConfig,
  modifiedConfig,
  plexConfigIsDirty,
  fetchingIssuers,
  plexFetchError,
  plexIsSaving,
  plexSaveSuccess,
  plexSaveError,

  currentDatabases,
  modifiedDatabases,
  fetchingDatabase,
  savingDatabase,
  databaseIsDirty,
  location,
  query,
  featureFlags,
  routeParams,
  dispatch,
}: {
  serviceInfo: ServiceInfo | undefined;
  companyID: string | undefined;
  selectedTenant: SelectedTenant | undefined;
  modifiedTenant: Tenant | undefined;
  fetchingTenants: boolean;
  fetchingSelectedTenant: boolean;
  editingTenant: boolean;
  savingTenant: boolean;
  saveSuccess: string;
  saveError: string;
  deletingTenant: boolean;
  deleteError: string;
  tenantURLs: TenantURL[] | undefined;
  tenantURLsIsDirty: boolean;
  fetchingTenantURLs: boolean;
  fetchingTenantURLsError: string;
  savingTenantURLs: boolean;
  tenantURLsToUpdate: TenantURL[];
  tenantURLsToCreate: TenantURL[];
  tenantURLsToDelete: string[];
  tenantURLDialogIsOpen: boolean;
  plexConfig: TenantPlexConfig | undefined;
  modifiedConfig: TenantPlexConfig | undefined;
  plexConfigIsDirty: boolean;
  fetchingIssuers: boolean;
  plexFetchError: string;
  plexIsSaving: boolean;
  plexSaveSuccess: UpdatePlexConfigReason | undefined;
  plexSaveError: string;
  currentDatabases: PaginatedResult<SqlshimDatabase> | undefined;
  modifiedDatabases: SqlshimDatabase[];
  fetchingDatabase: boolean;
  savingDatabase: boolean;
  databaseIsDirty: boolean;
  location: URL;
  query: URLSearchParams;
  featureFlags: FeatureFlags | undefined;
  routeParams: Record<string, string>;
  dispatch: AppDispatch;
}) => {
  const { tenantID } = routeParams;
  const handleSave = () => loadTenantPage(tenantID, dispatch);

  useEffect(() => {
    if (selectedTenant && tenantID) {
      if (tenantID !== selectedTenant.id) {
        redirect(
          `/tenants/${tenantID}?tenant_id=${tenantID}&company_id=${
            companyID as string
          }`
        );
      } else {
        loadTenantPage(tenantID, dispatch);
      }
    }
  }, [selectedTenant, companyID, tenantID, dispatch]);

  return (
    <>
      <form
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();

          selectedTenant &&
            dispatch(
              saveAllChanges(
                modifiedTenant,
                selectedTenant,
                plexConfigIsDirty,
                modifiedConfig,
                currentDatabases ? currentDatabases.data : [],
                modifiedDatabases,
                tenantURLsToCreate,
                tenantURLsToUpdate,
                tenantURLsToDelete,
                tenantID
              )
            );
        }}
      >
        <div className={PageCommon.listviewtablecontrols}>
          <PageTitle
            title={editingTenant ? 'Edit Tenant' : 'Tenant Details'}
            itemName={
              selectedTenant
                ? selectedTenant.name
                : fetchingTenants || fetchingSelectedTenant
                  ? '...'
                  : 'No tenants'
            }
            newdesign
          />

          <div className={PageCommon.listviewtablecontrolsToolTip}>
            <ToolTip>
              <>
                A tenant is a single instance of UserClouds's tech (APIs, user
                store etc). Each tenant can handle multiple applications.
                <a
                  href="https://docs.userclouds.com/docs/create-your-tenant"
                  title="UserClouds documentation"
                  target="new"
                  className={PageCommon.link}
                >
                  Learn more here.
                </a>
              </>
            </ToolTip>
          </div>
          <ButtonGroup className={PageCommon.listviewtablecontrolsButtonGroup}>
            {editingTenant && (
              <>
                <Button
                  type="submit"
                  theme="primary"
                  size="small"
                  isLoading={savingTenant || savingTenantURLs}
                  disabled={false} // todo
                >
                  Save
                </Button>
                <Button
                  theme="outline"
                  size="small"
                  isLoading={savingTenant || savingTenantURLs}
                  onClick={() => {
                    dispatch(toggleEditTenantMode(false));
                  }}
                >
                  Cancel
                </Button>
              </>
            )}
            {selectedTenant && selectedTenant.is_admin && !editingTenant && (
              <>
                <Button
                  theme="primary"
                  size="small"
                  isLoading={savingTenant || savingTenantURLs}
                  onClick={() => {
                    dispatch(toggleEditTenantMode(true));
                  }}
                >
                  Edit Settings
                </Button>
              </>
            )}
          </ButtonGroup>
        </div>
        <Card isDirty={editingTenant} detailview>
          {selectedTenant ? (
            <>
              {saveSuccess && (
                <InlineNotification theme="success">
                  {saveSuccess}
                </InlineNotification>
              )}
              {saveError && (
                <InlineNotification theme="alert">
                  {saveError}
                </InlineNotification>
              )}
              {deleteError && (
                <InlineNotification theme="alert">
                  {deleteError}
                </InlineNotification>
              )}
              <input type="hidden" name="id" value={selectedTenant.id} />
              <input
                type="hidden"
                name="company_id"
                value={selectedTenant.company_id}
              />
              <input
                type="hidden"
                name="primary_region"
                value={selectedTenant.primary_region}
              />
              <CardRow
                title="Basic details"
                tooltip="Configure the name and region of your tenant."
                collapsible
              >
                <div className={PageCommon.carddetailsrow}>
                  <Label className={GlobalStyles['mt-6']}>
                    ID
                    <br />
                    <InputReadOnly monospace>{selectedTenant.id}</InputReadOnly>
                  </Label>
                  <Label className={GlobalStyles['mt-6']}>
                    Name
                    <br />
                    {editingTenant && modifiedTenant ? (
                      <TextInput
                        name="name"
                        value={modifiedTenant.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(modifyTenantName(e.target.value));
                        }}
                      />
                    ) : (
                      <InputReadOnly>{selectedTenant.name}</InputReadOnly>
                    )}
                  </Label>
                  <Label className={GlobalStyles['mt-6']}>
                    URL
                    <br />
                    <InputReadOnly>
                      <a
                        href={selectedTenant.tenant_url}
                        rel="external"
                        target="new"
                        title="View the developer console for your tenant"
                        className={PageCommon.link}
                      >
                        {selectedTenant.tenant_url}
                      </a>
                    </InputReadOnly>
                  </Label>
                  <Label className={GlobalStyles['mt-6']}>
                    Use organizations
                    <InputReadOnly
                      name="use_orgs"
                      type="checkbox"
                      isChecked={selectedTenant.use_organizations}
                      isLocked
                    />
                  </Label>
                </div>
              </CardRow>
              {selectedTenant && selectedTenant.is_admin && (
                <CardRow
                  title="Database Connections"
                  tooltip={
                    <>
                      Specify additional URLs for your tenant. When you add a
                      custom tenant URL (eg. <b>auth.yourcompany.com</b>), you
                      should create a CNAME record that points to{' '}
                      <b>
                        {selectedTenant?.tenant_url?.replace(/https?:\/\//, '')}
                      </b>
                      .
                    </>
                  }
                  collapsible
                >
                  {selectedTenant &&
                  (currentDatabases || modifiedDatabases) &&
                  !fetchingDatabase &&
                  !savingDatabase ? (
                    <TenantDatabases
                      editing={editingTenant}
                      currentDatabases={currentDatabases}
                      modifiedDatabases={modifiedDatabases}
                      dispatch={dispatch}
                    />
                  ) : fetchingDatabase || savingDatabase ? (
                    'Loading ...'
                  ) : (
                    <Text>Error fetching database</Text>
                  )}
                </CardRow>
              )}
              {selectedTenant && selectedTenant.is_admin && (
                <CardRow
                  title="Custom Domain"
                  tooltip={
                    <>
                      Specify additional URLs for your tenant. When you add a
                      custom tenant URL (eg. <b>auth.yourcompany.com</b>), you
                      should create a CNAME record that points to{' '}
                      <b>
                        {selectedTenant?.tenant_url?.replace(/https?:\/\//, '')}
                      </b>
                      .
                    </>
                  }
                  collapsible
                >
                  {selectedTenant &&
                  tenantURLs &&
                  !fetchingTenantURLs &&
                  !savingTenantURLs ? (
                    <ConnectedTenantURLs />
                  ) : fetchingTenantURLs || savingTenantURLs ? (
                    'Loading ...'
                  ) : (
                    <Text>{fetchingTenantURLsError}</Text>
                  )}
                </CardRow>
              )}
              {selectedTenant && selectedTenant.is_admin ? (
                <CardRow
                  title="Trusted Issuer"
                  tooltip={
                    <>
                      Configure Social and other 3rd party OIDC Identity
                      Providers for Plex.
                      <a
                        href="https://docs.userclouds.com/docs/introduction-1"
                        title="UserClouds documentation for key concepts in authentication"
                        target="new"
                        className={PageCommon.link}
                      >
                        Learn more here.
                      </a>
                    </>
                  }
                  collapsible
                >
                  {selectedTenant &&
                  plexConfig &&
                  !fetchingIssuers &&
                  !plexIsSaving ? (
                    <ConnectedIssuers />
                  ) : fetchingIssuers || plexIsSaving ? (
                    'Loading ...'
                  ) : (
                    <Text>Error fetching database</Text>
                  )}
                </CardRow>
              ) : fetchingIssuers ? (
                'Loading ...'
              ) : (
                <Text>{plexFetchError}</Text>
              )}
              {serviceInfo?.uc_admin && editingTenant && (
                <Button
                  theme="dangerous"
                  className={PageCommon.listviewtablecontrolsButton}
                  isLoading={deletingTenant}
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to delete this tenant? This cannot be undone.'
                      )
                    ) {
                      dispatch(
                        handleDeleteTenant(
                          selectedTenant.company_id,
                          selectedTenant.id
                        )
                      );
                    }
                  }}
                >
                  Delete tenant
                </Button>
              )}
            </>
          ) : fetchingTenants || fetchingSelectedTenant ? (
            'Loading ...'
          ) : (
            <Text>
              This company doesn't have any tenants yet. You can{' '}
              <Link href={`/tenants/create?company_id=${companyID}`}>
                create one now.
              </Link>
            </Text>
          )}
        </Card>
      </form>
      <ConnectedTenantURLDialog onSave={handleSave} />
      <ConnectedDatabaseDetailsDialog tenantID={tenantID} />
      <ConnectedIssuerDetailsDialog />
    </>
  );
};

export default connect((state: RootState) => {
  return {
    serviceInfo: state.serviceInfo,
    companyID: state.selectedCompanyID,
    selectedTenant: state.selectedTenant,
    modifiedTenant: state.modifiedTenant,
    fetchingTenants: state.fetchingTenants,
    fetchingSelectedTenant: state.fetchingSelectedTenant,
    editingTenant: state.editingTenant,
    savingTenant: state.savingTenant,
    saveSuccess: state.saveTenantSuccess,
    saveError: state.saveTenantError,
    deletingTenant: state.deletingTenant,
    deleteError: state.deleteTenantError,
    tenantURLs: state.tenantURLs,
    tenantURLsIsDirty: state.tenantURLsIsDirty,
    tenantURLDialogIsOpen: state.tenantURLDialogIsOpen,
    tenantIssuerDialogIsOpen: state.tenantIssuerDialogIsOpen,
    currentDatabase: state.currentSqlshimDatabase,
    modifiedDatabase: state.modifiedSqlshimDatabase,
    currentDatabases: state.sqlShimDatabases,
    modifiedDatabases: state.modifiedSqlShimDatabases,
    fetchingDatabase: state.fetchingSqlshimDatabase,
    savingDatabase: state.savingSqlshimDatabase,
    databaseIsDirty: state.databaseIsDirty,
    fetchingTenantURLs: state.fetchingTenantURLs,
    fetchingTenantURLsError: state.fetchingTenantURLsError,
    savingTenantURLs: state.savingTenantURLs,
    tenantURLsToUpdate: state.tenantURLsToUpdate,
    tenantURLsToCreate: state.tenantURLsToCreate,
    tenantURLsToDelete: state.tenantURLsToDelete,
    oidcProvider: state.oidcProvider,
    plexConfig: state.tenantPlexConfig,
    fetchingIssuers: state.fetchingPlexConfig,
    modifiedConfig: state.modifiedPlexConfig,
    plexConfigIsDirty: state.plexConfigIsDirty,
    plexFetchError: state.fetchPlexConfigError,
    plexIsSaving: state.savingPlexConfig,
    plexSaveSuccess: state.savePlexConfigSuccess,
    plexSaveError: state.savePlexConfigError,
    location: state.location,
    featureFlags: state.featureFlags,
    query: state.query,
    routeParams: state.routeParams,
  };
})(TenantDetailsPage);
