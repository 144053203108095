import { v4 as uuidv4 } from 'uuid';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  CardRow,
  InlineNotification,
  Label,
  TextInput,
  Select,
} from '@userclouds/ui-component-lib';

import { redirect } from '../routing';
import { RootState, AppDispatch } from '../store';
import {
  createTenantRequest,
  createTenantSuccess,
  createTenantError,
} from '../actions/tenants';
import { createTenant } from '../API/tenants';
import { PageTitle } from '../mainlayout/PageWrap';
import { TenantState } from '../models/Tenant';
import Region, { PrimaryRegion } from '../models/Region';
import PageCommon from './PageCommon.module.css';
import { makeCleanPageLink } from '../AppNavigation';
import { FeatureFlags } from '../models/FeatureFlag';
import { featureIsEnabled } from '../util/featureflags';

const handleCreateTenant =
  (
    name: string,
    companyID: string,
    useOrgs: boolean,
    useAurora: boolean,
    primaryRegion: string,
    location: URL
  ) =>
  async (dispatch: AppDispatch) => {
    if (!name || name.length < 2 || name.length > 30) {
      dispatch(
        createTenantError(
          `Tenant name must be between 2 and 30 characters in length. Current length is ${
            name ? name.length : '0'
          }`
        )
      );
      return;
    }

    dispatch(createTenantRequest());
    return createTenant(
      companyID,
      {
        id: uuidv4(),
        name,
        company_id: companyID,
        use_organizations: useOrgs,
        primary_region: primaryRegion,
        state: TenantState.CREATING,
      },
      useAurora
    ).then(
      (tenant) => {
        dispatch(createTenantSuccess(tenant));
        redirect(`/?company_id=${tenant.company_id}&tenant_id=${tenant.id}`);
      },
      (error: Error) => {
        createTenantError(error.message);
      }
    );
  };

const CreateTenantPage = ({
  selectedCompanyID,
  isBusy,
  errorMessage,
  location,
  featureFlags,
  query,
  inDialog = false,
  dispatch,
}: {
  selectedCompanyID: string | undefined;
  isBusy: boolean;
  errorMessage: string;
  location: URL;
  featureFlags: FeatureFlags | undefined;
  query: URLSearchParams;
  inDialog?: boolean;
  dispatch: AppDispatch;
}) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const cleanQuery = makeCleanPageLink(query);
  const auroraTenantDBEnabled = featureIsEnabled(
    'auroratenantdb',
    featureFlags
  );

  return (
    <form
      name="create_tenant"
      onSubmit={(e: React.FormEvent) => {
        e.preventDefault();

        const form = e.target as HTMLFormElement;
        const data = new FormData(form);
        const name = data.get('name') as string;
        const useOrgs = data.get('use_orgs') as string;
        const useAurora = data.get('use_aurora') as string;
        const primaryRegion = data.get('primary_region') as Region;
        dispatch(
          handleCreateTenant(
            name.trim(),
            selectedCompanyID as string,
            useOrgs === 'true',
            useAurora === 'true',
            primaryRegion,
            location
          )
        ).then(() => {
          if (inDialog) {
            const dialog = form.closest('dialog') as HTMLDialogElement;
            dialog?.close();
          }
        });
      }}
    >
      {!inDialog && (
        <div className={PageCommon.listviewtablecontrols}>
          <PageTitle title="Add a new tenant" itemName="New tenant" newdesign />
          <ButtonGroup className={PageCommon.listviewtablecontrolsButtonGroup}>
            <Button
              size="small"
              theme="secondary"
              onClick={() => {
                if (
                  window.confirm(
                    'Are you sure you want to cancel tenant creation?'
                  )
                ) {
                  redirect(`/${cleanQuery}`);
                }
              }}
              isLoading={isBusy}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              size="small"
              theme="primary"
              disabled={!dirty}
              isLoading={isBusy}
            >
              Create Tenant
            </Button>
          </ButtonGroup>
        </div>
      )}
      {errorMessage && (
        <InlineNotification theme="alert">{errorMessage}</InlineNotification>
      )}
      <Card detailview>
        <CardRow title="Basic details" collapsible>
          <div className={PageCommon.carddetailsrow}>
            <Label>
              Tenant Name
              <br />
              <TextInput
                id="tenant_name"
                name="name"
                required
                placeholder="My first tenant"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setDirty(e.target.value.trim().length > 0);
                }}
              />
            </Label>
            <Label>
              Primary region
              <br />
              <Select
                name="primary_region"
                id="primary_region"
                defaultValue={PrimaryRegion}
              >
                {Object.values(Region).map((region: Region) => (
                  <option value={region} key={region}>
                    {region}
                  </option>
                ))}
              </Select>
            </Label>
            <Label>
              Use organizations
              <br />
              <input
                type="checkbox"
                name="use_orgs"
                id="use_orgs"
                value="true"
              />
            </Label>
            {auroraTenantDBEnabled && (
              <Label>
                Use Amazon Aurora
                <br />
                <input
                  type="checkbox"
                  name="use_aurora"
                  id="use_aurora"
                  value="true"
                />
              </Label>
            )}
          </div>
        </CardRow>
      </Card>
    </form>
  );
};

export default connect((state: RootState) => {
  return {
    selectedCompanyID: state.selectedCompanyID,
    isBusy: state.creatingTenant,
    errorMessage: state.createTenantError,
    location: state.location,
    query: state.query,
    featureFlags: state.featureFlags,
  };
})(CreateTenantPage);
