import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import {
  Button,
  ButtonGroup,
  Card,
  CardRow,
  CardFooter,
  EmptyState,
  GlobalStyles,
  IconButton,
  IconDeleteBin,
  IconFileList2,
  InlineNotification,
  InputReadOnly,
  Table,
  TableHead,
  TableRow,
  TableRowHead,
  TableBody,
  TableCell,
  Text,
  TextShortener,
} from '@userclouds/ui-component-lib';

import { makeCleanPageLink } from '../AppNavigation';
import {
  bulkDeleteAccessPolicies,
  bulkDeletePolicyTemplates,
  bulkDeleteTransformers,
  fetchAccessPolicies,
  fetchPolicyTemplates,
  fetchTransformers,
  fetchUserPolicyPermissions,
} from '../thunks/tokenizer';
import {
  toggleAccessPolicyEditMode,
  toggleAccessPolicyForDelete,
  togglePolicyTemplatesEditMode,
  togglePolicyTemplateForDelete,
  toggleTransformerEditMode,
  toggleTransformerForDelete,
  changeAccessPolicySearchFilter,
  changeAccessPolicyTemplateSearchFilter,
  changeTransformerSearchFilter,
} from '../actions/tokenizer';
import { AppDispatch, RootState } from '../store';
import PaginatedResult from '../models/PaginatedResult';
import AccessPolicy, {
  AccessPolicyTemplate,
  ACCESS_POLICIES_COLUMNS,
  ACCESS_POLICIES_PREFIX,
  ACCESS_POLICY_TEMPLATE_COLUMNS,
  ACCESS_POLICY_TEMPLATE_PREFIX,
} from '../models/AccessPolicy';
import Transformer, {
  TRANSFORMERS_PREFIX,
  TRANSFORMER_COLUMNS,
  TransformTypeFriendly,
} from '../models/Transformer';
import PermissionsOnObject from '../models/authz/Permissions';
import { PageTitle } from '../mainlayout/PageWrap';
import Link from '../controls/Link';
import Pagination from '../controls/Pagination';
import PageCommon from './PageCommon.module.css';
import Search from '../controls/Search';
import { Filter } from '../models/authz/SearchFilters';
import Breadcrumbs from '../controls/Breadcrumbs';

type AccessPoliciesProps = {
  selectedCompanyID: string | undefined;
  selectedTenantID: string | undefined;
  editMode: boolean;
  policies: PaginatedResult<AccessPolicy> | undefined;
  fetchError: string | undefined;
  isFetching: boolean;
  isDeleting: boolean;
  deleteQueue: string[];
  deleteSuccess: string;
  deleteErrors: string[];
  permissions: PermissionsOnObject;
  policySearchFilter: Filter;
  query: URLSearchParams;
  dispatch: AppDispatch;
};
const AccessPolicyList = ({
  selectedCompanyID,
  selectedTenantID,
  editMode,
  policies,
  fetchError,
  isFetching,
  isDeleting,
  deleteQueue,
  deleteSuccess,
  deleteErrors,
  permissions,
  policySearchFilter,
  query,
  dispatch,
}: AccessPoliciesProps) => {
  const cleanQuery = makeCleanPageLink(query);

  return (
    <>
      <div className={PageCommon.tablecontrols}>
        <Search
          columns={ACCESS_POLICIES_COLUMNS}
          changeSearchFilter={(filter: Filter) => {
            dispatch(changeAccessPolicySearchFilter(filter));
          }}
          prefix={ACCESS_POLICIES_PREFIX}
          searchFilter={policySearchFilter}
        />
        {!editMode && (
          <Pagination
            prev={policies?.prev}
            next={policies?.next}
            isLoading={isFetching}
            prefix={ACCESS_POLICIES_PREFIX}
          />
        )}
      </div>
      {policies ? (
        policies.data && policies.data.length ? (
          <>
            {!!deleteErrors.length && (
              <InlineNotification theme="alert" elementName="div">
                <ul>
                  {deleteErrors.map((error: string) => (
                    <li key={`access_policies_error_${error}`}>{error}</li>
                  ))}
                </ul>
              </InlineNotification>
            )}
            {deleteSuccess && (
              <InlineNotification theme="success">
                {deleteSuccess}
              </InlineNotification>
            )}
            <CardRow>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableRowHead>Name</TableRowHead>
                    <TableRowHead>ID</TableRowHead>
                    <TableRowHead>Version</TableRowHead>
                    {editMode && <TableRowHead />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {policies.data.map((entry) => (
                    <TableRow
                      key={entry.id + '-' + entry.version}
                      className={
                        deleteQueue.includes(entry.id)
                          ? PageCommon.queuedfordelete
                          : ''
                      }
                    >
                      <TableCell>
                        <Link
                          href={`/tokenizer/accesspolicies/${
                            entry.id
                          }/${entry.version.toString()}${cleanQuery}`}
                        >
                          <Text>{entry.name || '[Unnamed policy]'}</Text>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <TextShortener text={entry.id} length={36} />
                      </TableCell>
                      <TableCell>{entry.version}</TableCell>
                      {editMode && (
                        <TableCell align="right">
                          <IconButton
                            icon={<IconDeleteBin />}
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              dispatch(toggleAccessPolicyForDelete(entry.id));
                            }}
                            title="Delete Policy"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardRow>
            {!editMode && (policies.has_prev || policies.has_next) && (
              <Pagination
                prev={policies.prev}
                next={policies.next}
                isLoading={isFetching}
                prefix={ACCESS_POLICIES_PREFIX}
              />
            )}
            <CardRow>
              {permissions.create && (
                <Button theme="outline" className={GlobalStyles['mt-6']}>
                  <Link
                    href={`/tokenizer/accesspolicies/create${cleanQuery}`}
                    applyStyles={false}
                  >
                    + Add Access Policy
                  </Link>
                </Button>
              )}
            </CardRow>
            {permissions.delete && (
              <CardFooter>
                {!editMode ? (
                  <Button
                    theme="secondary"
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      dispatch(toggleAccessPolicyEditMode());
                    }}
                  >
                    Edit Access Policies
                  </Button>
                ) : (
                  <ButtonGroup>
                    <Button
                      theme="primary"
                      onClick={() => {
                        if (selectedTenantID) {
                          dispatch(
                            bulkDeleteAccessPolicies(
                              selectedTenantID,
                              deleteQueue
                            )
                          );
                        }
                      }}
                      disabled={!deleteQueue.length || isFetching || isDeleting}
                      isLoading={isFetching || isDeleting}
                    >
                      Save changes
                    </Button>
                    <Button
                      theme="secondary"
                      onClick={() => {
                        dispatch(toggleAccessPolicyEditMode());
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                )}
              </CardFooter>
            )}
          </>
        ) : (
          <CardRow>
            <EmptyState
              title="No access policies"
              image={<IconFileList2 size="large" />}
            >
              <Button theme="secondary">
                <Link
                  href={`/tokenizer/accesspolicies/create${cleanQuery}`}
                  applyStyles={false}
                >
                  + Add access policy
                </Link>
              </Button>
            </EmptyState>
          </CardRow>
        )
      ) : isFetching ? (
        <Text>Loading policies...</Text>
      ) : (
        <InlineNotification theme="alert">
          {fetchError || 'Something went wrong'}
        </InlineNotification>
      )}
    </>
  );
};
const ConnectedAccessPolicyList = connect((state: RootState) => {
  return {
    selectedCompanyID: state.selectedCompanyID,
    selectedTenantID: state.selectedTenantID,
    editMode: state.accessPolicyEditMode,
    policies: state.accessPolicies,
    fetchError: state.accessPolicyFetchError,
    isFetching: state.fetchingAccessPolicies,
    isDeleting: state.deletingAccessPolicy,
    deleteQueue: state.accessPoliciesDeleteQueue,
    deleteSuccess: state.deleteAccessPoliciesSuccess,
    deleteErrors: state.deleteAccessPoliciesErrors,
    policySearchFilter: state.accessPolicySearchFilter,
    query: state.query,
  };
})(AccessPolicyList);

type PolicyTemplatesProps = {
  selectedCompanyID: string | undefined;
  selectedTenantID: string | undefined;
  editMode: boolean;
  templates: PaginatedResult<AccessPolicyTemplate> | undefined;
  fetchError: string | undefined;
  isFetching: boolean;
  isDeleting: boolean;
  deleteQueue: string[];
  deleteSuccess: string;
  deleteErrors: string[];
  permissions: PermissionsOnObject;
  policyTemplateSearchFilter: Filter;
  query: URLSearchParams;
  dispatch: AppDispatch;
};
const PolicyTemplateList = ({
  selectedCompanyID,
  selectedTenantID,
  editMode,
  templates,
  fetchError,
  isFetching,
  isDeleting,
  deleteQueue,
  deleteSuccess,
  deleteErrors,
  permissions,
  policyTemplateSearchFilter,
  query,
  dispatch,
}: PolicyTemplatesProps) => {
  const cleanQuery = makeCleanPageLink(query);

  return (
    <>
      <div className={PageCommon.tablecontrols}>
        <Search
          columns={ACCESS_POLICY_TEMPLATE_COLUMNS}
          changeSearchFilter={(filter: Filter) => {
            dispatch(changeAccessPolicyTemplateSearchFilter(filter));
          }}
          prefix={ACCESS_POLICY_TEMPLATE_PREFIX}
          searchFilter={policyTemplateSearchFilter}
        />
        {!editMode && (
          <Pagination
            prev={templates?.prev}
            next={templates?.next}
            isLoading={isFetching}
            prefix={ACCESS_POLICY_TEMPLATE_PREFIX}
          />
        )}
      </div>
      {templates ? (
        templates.data && templates.data.length ? (
          <>
            {!!deleteErrors.length && (
              <InlineNotification theme="alert" elementName="div">
                <ul>
                  {deleteErrors.map((error: string) => (
                    <li key={`policy_templates_error_${error}`}>{error}</li>
                  ))}
                </ul>
              </InlineNotification>
            )}
            {deleteSuccess && (
              <InlineNotification theme="success">
                {deleteSuccess}
              </InlineNotification>
            )}
            <CardRow>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableRowHead>Name</TableRowHead>
                    <TableRowHead>ID</TableRowHead>

                    <TableRowHead>Description</TableRowHead>
                    <TableRowHead>Version</TableRowHead>
                    {editMode && <TableRowHead />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {templates.data.map((entry) => (
                    <TableRow
                      key={entry.id + '-' + entry.version}
                      className={
                        deleteQueue.includes(entry.id)
                          ? PageCommon.queuedfordelete
                          : ''
                      }
                    >
                      <TableCell>
                        <Link
                          href={`/tokenizer/policytemplates/${
                            entry.id
                          }/${entry.version.toString()}${cleanQuery}`}
                        >
                          <Text>{entry.name || '[Unnamed policy]'}</Text>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <TextShortener text={entry.id} length={36} />
                      </TableCell>
                      <TableCell>
                        <Text>{entry.description}</Text>
                      </TableCell>
                      <TableCell>{entry.version}</TableCell>
                      {editMode && (
                        <TableCell align="right">
                          <IconButton
                            icon={<IconDeleteBin />}
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              dispatch(togglePolicyTemplateForDelete(entry.id));
                            }}
                            title="Delete Policy Template"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardRow>
            {!editMode && (templates.has_prev || templates.has_next) && (
              <Pagination
                prev={templates.prev}
                next={templates.next}
                isLoading={isFetching}
                prefix={ACCESS_POLICY_TEMPLATE_PREFIX}
              />
            )}
            <CardRow>
              {permissions.create && (
                <Button theme="outline" className={GlobalStyles['mt-6']}>
                  <Link
                    href={`/tokenizer/policytemplates/create${cleanQuery}`}
                    applyStyles={false}
                  >
                    + Add Policy Template
                  </Link>
                </Button>
              )}
            </CardRow>
            {permissions.delete && (
              <CardFooter>
                {!editMode ? (
                  <Button
                    theme="secondary"
                    onClick={() => {
                      dispatch(togglePolicyTemplatesEditMode(true));
                    }}
                  >
                    Edit Policy Templates
                  </Button>
                ) : (
                  <ButtonGroup>
                    <Button
                      theme="primary"
                      onClick={() => {
                        if (selectedTenantID) {
                          dispatch(
                            bulkDeletePolicyTemplates(
                              selectedTenantID,
                              deleteQueue
                            )
                          );
                        }
                      }}
                      disabled={!deleteQueue.length || isFetching || isDeleting}
                      isLoading={isFetching || isDeleting}
                    >
                      Save changes
                    </Button>
                    <Button
                      theme="secondary"
                      onClick={() => {
                        dispatch(togglePolicyTemplatesEditMode(false));
                      }}
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                )}
              </CardFooter>
            )}
          </>
        ) : (
          <CardRow>
            <EmptyState
              title="No policy templates"
              image={<IconFileList2 size="large" />}
            >
              <Button theme="secondary">
                <Link
                  href={`/tokenizer/policytemplates/create${cleanQuery}`}
                  applyStyles={false}
                >
                  + Add policy template
                </Link>
              </Button>
            </EmptyState>
          </CardRow>
        )
      ) : isFetching ? (
        <Text>Loading policy templates...</Text>
      ) : (
        <InlineNotification theme="alert">
          {fetchError || 'Something went wrong'}
        </InlineNotification>
      )}
    </>
  );
};
const ConnectedPolicyTemplateList = connect((state: RootState) => {
  return {
    selectedCompanyID: state.selectedCompanyID,
    selectedTenantID: state.selectedTenantID,
    editMode: state.policyTemplateEditMode,
    templates: state.policyTemplates,
    fetchError: state.policyTemplatesFetchError,
    isFetching: state.fetchingPolicyTemplates,
    isDeleting: state.deletingPolicyTemplate,
    deleteQueue: state.policyTemplatesDeleteQueue,
    deleteSuccess: state.deletePolicyTemplatesSuccess,
    deleteErrors: state.deletePolicyTemplatesErrors,
    policyTemplateSearchFilter: state.accessPolicyTemplateSearchFilter,
    query: state.query,
  };
})(PolicyTemplateList);

type TransformersProps = {
  selectedCompanyID: string | undefined;
  selectedTenantID: string | undefined;
  editMode: boolean;
  transformers: PaginatedResult<Transformer> | undefined;
  fetchError: string | undefined;
  isFetching: boolean;
  isDeleting: boolean;
  deleteQueue: string[];
  deleteSuccess: string;
  deleteErrors: string[];
  permissions: PermissionsOnObject;
  transformerSearchFilter: Filter;
  query: URLSearchParams;
  dispatch: AppDispatch;
};

const TransformerList = ({
  selectedCompanyID,
  selectedTenantID,
  editMode,
  transformers,
  fetchError,
  isFetching,
  isDeleting,
  deleteQueue,
  deleteSuccess,
  deleteErrors,
  permissions,
  transformerSearchFilter,
  query,
  dispatch,
}: TransformersProps) => {
  const cleanQuery = makeCleanPageLink(query);

  return (
    <>
      <div className={PageCommon.tablecontrols}>
        <Search
          columns={TRANSFORMER_COLUMNS}
          changeSearchFilter={(filter: Filter) => {
            dispatch(changeTransformerSearchFilter(filter));
          }}
          prefix={TRANSFORMERS_PREFIX}
          searchFilter={transformerSearchFilter}
        />
        {!editMode && (
          <Pagination
            prev={transformers?.prev}
            next={transformers?.next}
            prefix={TRANSFORMERS_PREFIX}
            isLoading={isFetching}
          />
        )}
      </div>
      {transformers ? (
        transformers.data && transformers.data.length ? (
          <>
            {!!deleteErrors.length && (
              <InlineNotification
                theme="alert"
                elementName="div"
                className={GlobalStyles['mb-3']}
              >
                <ul>
                  {deleteErrors.map((error: string) => (
                    <li key={`transformers_error_${error}`}>{error}</li>
                  ))}
                </ul>
              </InlineNotification>
            )}
            {deleteSuccess && (
              <InlineNotification
                theme="success"
                className={GlobalStyles['mb-3']}
              >
                {deleteSuccess}
              </InlineNotification>
            )}
            <CardRow>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableRowHead>Name</TableRowHead>
                    <TableRowHead>Transform Type</TableRowHead>
                    <TableRowHead>ID</TableRowHead>
                    {editMode && <TableRowHead />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transformers.data.map((entry) => (
                    <TableRow
                      key={entry.id}
                      className={
                        deleteQueue.includes(entry.id)
                          ? PageCommon.queuedfordelete
                          : ''
                      }
                    >
                      <TableCell>
                        <Link
                          href={`/tokenizer/transformers/${entry.id}/${entry.version}${cleanQuery}`}
                        >
                          <Text>{entry.name}</Text>
                        </Link>
                      </TableCell>
                      <TableCell>
                        <InputReadOnly>
                          {TransformTypeFriendly[entry.transform_type]}
                        </InputReadOnly>
                      </TableCell>
                      <TableCell>
                        <TextShortener text={entry.id} length={36} />
                      </TableCell>
                      {editMode && (
                        <TableCell align="right">
                          <IconButton
                            icon={<IconDeleteBin />}
                            onClick={(e: React.MouseEvent) => {
                              e.preventDefault();
                              dispatch(toggleTransformerForDelete(entry.id));
                            }}
                            title="Delete transformer"
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardRow>
            {!editMode && (transformers.has_prev || transformers.has_next) && (
              <Pagination
                prev={transformers.prev}
                next={transformers.next}
                prefix={TRANSFORMERS_PREFIX}
                isLoading={isFetching}
              />
            )}
            {permissions.create && (
              <Button theme="outline" className={GlobalStyles['mt-6']}>
                <Link
                  href={`/tokenizer/transformers/create${cleanQuery}`}
                  applyStyles={false}
                >
                  + Add Transformer
                </Link>
              </Button>
            )}
            {permissions.delete && (
              <CardFooter>
                {!editMode ? (
                  <Button
                    theme="secondary"
                    onClick={() => dispatch(toggleTransformerEditMode(true))}
                  >
                    Edit Transformers
                  </Button>
                ) : (
                  <ButtonGroup>
                    <Button
                      theme="primary"
                      onClick={() => {
                        if (selectedTenantID) {
                          dispatch(
                            bulkDeleteTransformers(
                              selectedTenantID,
                              deleteQueue
                            )
                          );
                        }
                      }}
                      disabled={!deleteQueue.length || isFetching || isDeleting}
                      isLoading={isFetching || isDeleting}
                    >
                      Save changes
                    </Button>
                    <Button
                      theme="secondary"
                      onClick={() =>
                        dispatch(dispatch(toggleTransformerEditMode(false)))
                      }
                    >
                      Cancel
                    </Button>
                  </ButtonGroup>
                )}
              </CardFooter>
            )}
          </>
        ) : (
          <CardRow>
            <EmptyState
              title="No transformers"
              image={<IconFileList2 size="large" />}
            >
              <Button theme="secondary">
                <Link
                  href={`/tokenizer/transformers/create${cleanQuery}`}
                  applyStyles={false}
                >
                  + Add transformer
                </Link>
              </Button>
            </EmptyState>
          </CardRow>
        )
      ) : isFetching ? (
        <Text>Loading transformers...</Text>
      ) : (
        <InlineNotification theme="alert">
          {fetchError || 'Something went wrong'}
        </InlineNotification>
      )}
    </>
  );
};
const ConnectedTransformerList = connect((state: RootState) => {
  return {
    selectedCompanyID: state.selectedCompanyID,
    selectedTenantID: state.selectedTenantID,
    editMode: state.transformerEditMode,
    transformers: state.transformers,
    fetchError: state.transformerFetchError,
    isFetching: state.fetchingTransformers,
    isDeleting: state.deletingTransformer,
    deleteQueue: state.transformersDeleteQueue,
    deleteSuccess: state.deleteTransformersSuccess,
    deleteErrors: state.deleteTransformersErrors,
    transformerSearchFilter: state.transformerSearchFilter,
    query: state.query,
  };
})(TransformerList);

const TokenizerPoliciesPage = ({
  selectedTenantID,
  userPolicyPermissions,
  permissionsFetchError,
  accessEditMode,
  templatesEditMode,
  transformerEditMode,
  location,
  query,
  dispatch,
}: {
  selectedTenantID: string | undefined;
  userPolicyPermissions: PermissionsOnObject | undefined;
  permissionsFetchError: string;
  accessEditMode: boolean;
  templatesEditMode: boolean;
  transformerEditMode: boolean;
  location: URL;
  query: URLSearchParams;
  dispatch: AppDispatch;
}) => {
  useEffect(() => {
    if (selectedTenantID) {
      dispatch(fetchUserPolicyPermissions(selectedTenantID));
    }
  }, [dispatch, selectedTenantID]);

  useEffect(() => {
    if (selectedTenantID && userPolicyPermissions?.read) {
      dispatch(fetchAccessPolicies(selectedTenantID, query, false));
      dispatch(fetchPolicyTemplates(selectedTenantID, query));
      dispatch(fetchTransformers(selectedTenantID, query));
    }
  }, [dispatch, userPolicyPermissions, selectedTenantID, query]);
  return (
    <>
      <Breadcrumbs />
      <PageTitle
        title="Policies"
        description={
          <>
            {'Manage your token transformers and access policies. '}
            <a
              href="https://docs.userclouds.com/docs/how-it-works"
              title="UserClouds documentation for tokenizer"
              target="new"
              className={PageCommon.link}
            >
              Learn more here.
            </a>
          </>
        }
      />
      {userPolicyPermissions?.read ? (
        <>
          <Card
            id="accessPolicies"
            title="Access Policies"
            description={
              <>
                {
                  'Access Policies control the circumstances in which data can be retrieved or edited. '
                }
                <a
                  href="https://docs.userclouds.com/docs/access-policies-1"
                  title="UserClouds documentation for access policies"
                  target="new"
                  className={PageCommon.link}
                >
                  Learn more here.
                </a>
              </>
            }
            isDirty={accessEditMode}
          >
            <ConnectedAccessPolicyList permissions={userPolicyPermissions} />
          </Card>

          <Card
            id="policyTemplates"
            title="Access Policy Templates"
            description={
              <>
                {
                  'Policy templates are parametrizable functions that can be parametrized to create access policies. '
                }
                <a
                  href="https://docs.userclouds.com/docs/create-an-access-policy-copy"
                  title="UserClouds documentation on creating access policies"
                  target="new"
                  className={PageCommon.link}
                >
                  Learn more here.
                </a>
              </>
            }
            isDirty={templatesEditMode}
          >
            <ConnectedPolicyTemplateList permissions={userPolicyPermissions} />
          </Card>

          <Card
            id="transformers"
            title="Transformers"
            description={
              <>
                {
                  'Transformers are re-usable functions that manipulate data in UserClouds. '
                }
                <a
                  href="https://docs.userclouds.com/docs/transformers-1"
                  title="UserClouds documentation for transformers"
                  target="new"
                  className={PageCommon.link}
                >
                  Learn more here.
                </a>
              </>
            }
            isDirty={transformerEditMode}
          >
            <ConnectedTransformerList permissions={userPolicyPermissions} />
          </Card>
        </>
      ) : userPolicyPermissions ? (
        <Card
          title="Request access"
          description="You do not have permission to view any policies. Please contact your administrator to request access."
        >
          {permissionsFetchError && (
            <InlineNotification theme="alert">
              {permissionsFetchError}
            </InlineNotification>
          )}
        </Card>
      ) : (
        <Card
          title="Loading..."
          description="Loading policies and permissions."
        />
      )}
    </>
  );
};

export default connect((state: RootState) => {
  return {
    selectedTenantID: state.selectedTenantID,
    userPolicyPermissions: state.userPolicyPermissions,
    permissionsFetchError: state.userPolicyPermissionsFetchError,
    accessEditMode: state.accessPolicyEditMode,
    templatesEditMode: state.policyTemplateEditMode,
    transformerEditMode: state.transformerEditMode,
    location: state.location,
    query: state.query,
  };
})(TokenizerPoliciesPage);
